import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "insurance-create-container" }
const _hoisted_2 = {
  key: 0,
  class: "insurance-create-error"
}
const _hoisted_3 = { class: "insurance-create" }
const _hoisted_4 = ["disabled"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Dialog"], {
    visible: $setup.calendarStore.insuranceModalOpen,
    "onUpdate:visible": _cache[4] || (_cache[4] = $event => (($setup.calendarStore.insuranceModalOpen) = $event)),
    header: "Créer une assurance",
    onKeydown: $setup.handleKeyDown
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode($setup["FloatLabel"], { variant: "on" }, {
          default: _withCtx(() => [
            _createVNode($setup["InputText"], {
              id: "insurance-name",
              modelValue: $setup.insuranceName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.insuranceName) = $event)),
              style: {"min-width":"20rem"}
            }, null, 8 /* PROPS */, ["modelValue"]),
            _cache[5] || (_cache[5] = _createElementVNode("label", { for: "insurance-name" }, "Nom de l'assurance", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["FloatLabel"], { variant: "on" }, {
          default: _withCtx(() => [
            _createVNode($setup["InputText"], {
              id: "confirm-insurance-name",
              modelValue: $setup.confirmedInsuranceName,
              "onUpdate:modelValue": [
                _cache[1] || (_cache[1] = $event => (($setup.confirmedInsuranceName) = $event)),
                _cache[3] || (_cache[3] = $event => ($setup.updateShowErrorMessage()))
              ],
              style: {"min-width":"20rem"},
              onChange: _cache[2] || (_cache[2] = $event => ($setup.showErrorMessage = $setup.insuranceName !== $setup.confirmedInsuranceName))
            }, null, 8 /* PROPS */, ["modelValue"]),
            _cache[6] || (_cache[6] = _createElementVNode("label", { for: "confirmedInsuranceName" }, "Confirmer le nom de l'assurance", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        }),
        ($setup.showErrorMessage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Les noms ne correspondent pas "))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            class: "insurance-create-button",
            disabled: $setup.showErrorMessage,
            onClick: $setup.handleCreateInsurance
          }, " Créer ", 8 /* PROPS */, _hoisted_4)
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible"]))
}