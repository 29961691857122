export const STATES_LIST = [
  { name: "À faire", value: "todo" },
  { name: "À venir", value: "coming" },
  { name: "Expertise", value: "expertise" },
  { name: "En attente", value: "waiting" },
  { name: "Casse", value: "breakage" },
  { name: "Réparation", value: "repairing" },
  { name: "Remontage", value: "reassembly" },
  { name: "Terminé", value: "finished" },
  { name: "Livré", value: "delivered" },
]
