import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "calendar-week-chart" }
const _hoisted_2 = { class: "calendar-chart" }
const _hoisted_3 = { ref: "chartRef" }
const _hoisted_4 = {
  key: 0,
  class: "calendar-done-work"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("canvas", _hoisted_3, null, 512 /* NEED_PATCH */)
    ]),
    ($setup.doneWorkPercentage > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($setup.doneWorkPercentage) + " % ", 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    ($setup.doneWorkPercentage > 0)
      ? _withDirectives((_openBlock(), _createBlock($setup["CircleHelp"], {
          key: 1,
          class: "calendar-done-work-explanation"
        }, null, 512 /* NEED_PATCH */)), [
          [
            _directive_tooltip,
            `Le pourcentage au centre représente le travail effectué sur la semaine par rapport aux prévisions. Les couleurs correspondent aux différents avancements par domaine par rapport à la capacité.`,
            void 0,
            { top: true }
          ]
        ])
      : _createCommentVNode("v-if", true)
  ]))
}