import { Controller } from "@hotwired/stimulus"

const HIDDEN_CLASS = "-hidden"

export default class Form extends Controller {
  static targets = ["submit"]
  static values = {
    additionalId: String,
  }

  onReplacementCarNeed(event) {
    this.replacementCarTarget.classList.toggle(HIDDEN_CLASS, !event.currentTarget.checked)
  }

  openInsuranceModal() {
    document.getElementById(this.additionalIdValue).classList.remove(HIDDEN_CLASS)
  }
}
