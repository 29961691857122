<script setup>
import { asyncComputed } from "@vueuse/core/index.cjs"
import SplitButton from "primevue/splitbutton"
import { useToast } from "primevue/usetoast"
import { h, ref } from "vue"
import { renderToString } from "vue/server-renderer"

import CarState from "../../../../../cars/static_src/cars/components/tooltips/CarState.vue"
import RepairingStatesDialog from "./dialogs/RepairingStates.vue"
import WaitingDateDialog from "./dialogs/WaitingDate.vue"

import { useCalendarStore } from "../../stores/calendar.js"
import { carObject, getStateList, stateName } from "../../utils/cars.js"
import { getFullDate } from "../../utils/date.js"
import { svgLink } from "../../utils/svg.js"

const props = defineProps({
  object: {
    type: Object,
    required: true,
  },
})

const calendarStore = useCalendarStore()
const toast = useToast()
const repairingStatesModalOpen = ref(false)
const waitingDateModalOpen = ref(false)

const openStates = (event) => {
  event.currentTarget.children[0].children[0].nextElementSibling.click()
}

const onSelectState = (state) => {
  if (state === "repairing") {
    repairingStatesModalOpen.value = true
    return
  }
  if (state === "waiting") {
    waitingDateModalOpen.value = true
    return
  }
  calendarStore.updateCarState(props.object, state, null).then(() => {
    toast.add({
      severity: "success",
      summary: `${carObject(props.object).owner_last_name} | ${carObject(props.object).model}`,
      detail: `Le véhicule est maintenant dans l'état : ${stateName(state)}`,
      life: 4000,
    })
  })
}

const onSelectRepairingState = (states) => {
  calendarStore.updateCarState(props.object, "repairing", states).then(() => {
    toast.add({
      severity: "success",
      summary: `${carObject(props.object).owner_last_name} | ${carObject(props.object).model}`,
      detail: `Le véhicule est maintenant dans l'état : ${stateName("repairing")}`,
      life: 4000,
    })
  })
  repairingStatesModalOpen.value = false
}

const onSelectWaitingDate = async(date) => {
  if (!date) return
  const car = carObject(props.object)
  await calendarStore.updateCarInCharge({ ...car, waiting_date: date })
  calendarStore.updateCarState(props.object, "waiting", null).then(() => {
    toast.add({
      severity: "success",
      summary: `${car.owner_last_name} | ${car.model}`,
      detail: `Le véhicule est maintenant en attente depuis le ${getFullDate(date)}`,
      life: 4000,
    })
  })
  waitingDateModalOpen.value = false
}

const stateTooltipOptions = asyncComputed(async() => {
  const htmlContent = await renderToString(h(CarState, { object: props.object }))
  return { escape: false, value: htmlContent }
})
</script>

<template>
  <button
    :id="`car-${carObject(props.object).id}`"
    v-tooltip.left="stateTooltipOptions"
    :class="['calendar-week-day-car-state-container', carObject(props.object).is_deleted ? '-deleted' : '']"
    @click="openStates"
  >
    <div
      v-if="calendarStore.isHomepage && props.object.type === 'car'"
      :class="`car__card-state -${carObject(props.object).state}`"
    />
    <svg
      v-else-if="calendarStore.isHomepage && props.object.type === 'work'"
      :class="`car__delay-work-icon -${carObject(props.object).state}`"
    >
      <use :href="svgLink('cars', 'delay')" />
    </svg>
    <SplitButton
      v-else
      class="calendar-week-day-car-state"
      :model="getStateList()"
      :pt="{ pcMenu: { root: { class: 'calendar-week-day-car-state-menu' } } }"
    >
      <template #item="slotProps">
        <button
          v-if="slotProps.item.value !== carObject(props.object).state || carObject(props.object).state === 'repairing'"
          class="state-selecter"
          @click="onSelectState(slotProps.item.value)"
        >
          <div :class="`car__card-state -${slotProps.item.value}`" />
          {{ slotProps.item.label }}
        </button>
        <button
          v-else
          class="state-selecter"
          disabled
        >
          <div :class="`car__card-state -${slotProps.item.value}`" />
          {{ slotProps.item.label }}
        </button>
      </template>
      <div
        v-if="props.object.type === 'car'"
        :class="`car__card-state -${carObject(props.object).state}`"
      />
      <svg
        v-else
        :class="`car__delay-work-icon -${carObject(props.object).state}`"
      >
        <use :href="svgLink('cars', 'delay')" />
      </svg>
    </SplitButton>
    <RepairingStatesDialog
      :open-modal="repairingStatesModalOpen"
      :object="props.object"
      :car="carObject(props.object)"
      :handle-validate="onSelectRepairingState"
    />
    <WaitingDateDialog
      v-model="waitingDateModalOpen"
      :object="props.object"
      :car="carObject(props.object)"
      :handle-validate="onSelectWaitingDate"
    />
  </button>
</template>

<style lang="scss" scoped>
@import '../../../../../ui/static_src/ui/base/mixins';

.calendar-week-day-car-state {
  display: flex;
  width: .8rem;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  min-width: 0px;
  cursor: pointer;
}

.state-selecter {
  @include body;
  width: 7rem;
  padding: .1rem .4rem;
  border-radius: var(--radius);

  &:disabled:hover {
    background-color: transparent
  }
}

.calendar-week-day-car-state-container {
  padding: 0;
  margin: 0;
  padding-inline: 0;
  padding-block: 0;

  &:hover {
    background-color: transparent;
  }

  &.-deleted {
    opacity: .4;
  }
}
</style>
