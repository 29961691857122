import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "calendar-week-chart" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["CalendarChargeChart"], {
      work: $setup.weekWork,
      capacity: $props.weekCapacity,
      "font-size-value": "high",
      "delay-animation": $setup.props.delayAnimation
    }, null, 8 /* PROPS */, ["work", "capacity", "delay-animation"])
  ]))
}