import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "car-repairing-states"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createTextVNode(_toDisplayString($setup.STATES_LIST.find(s => s.value === $setup.state).name) + " ", 1 /* TEXT */),
    ($setup.state === 'repairing')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass(['car-repairing-state', $setup.baseObject.sheet_metal_work_hours === 0 ? '-disable' : ''])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(`car__card-state -repairing -${$setup.car.sheet_metal_state}`)
            }, null, 2 /* CLASS */),
            _cache[0] || (_cache[0] = _createTextVNode(" Tôlerie "))
          ], 2 /* CLASS */),
          _createElementVNode("div", {
            class: _normalizeClass(['car-repairing-state', $setup.baseObject.painting_hours === 0 ? '-disable' : ''])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(`car__card-state -repairing -${$setup.car.painting_state}`)
            }, null, 2 /* CLASS */),
            _cache[1] || (_cache[1] = _createTextVNode(" Peinture "))
          ], 2 /* CLASS */),
          _createElementVNode("div", {
            class: _normalizeClass(['car-repairing-state', $setup.baseObject.mechanic_hours === 0 ? '-disable' : ''])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(`car__card-state -repairing -${$setup.car.mechanic_state}`)
            }, null, 2 /* CLASS */),
            _cache[2] || (_cache[2] = _createTextVNode(" Mécanique "))
          ], 2 /* CLASS */)
        ]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}