import { createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade-slide" }, {
    default: _withCtx(() => [
      ($setup.showMessage)
        ? (_openBlock(), _createBlock($setup["Message"], {
            key: 0,
            class: "calendar-message",
            severity: "info"
          }, {
            default: _withCtx(() => [
              _createVNode($setup["CircleArrowOutUpLeft"], { size: "18" }),
              _cache[0] || (_cache[0] = _createTextVNode(" Appuyez sur la touche ÉCHAP pour quitter le mode report "))
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }))
}