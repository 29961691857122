import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "input-subtitle" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Dialog"], {
    visible: $setup.carStore.showDeletionDialog,
    "onUpdate:visible": _cache[2] || (_cache[2] = $event => (($setup.carStore.showDeletionDialog) = $event)),
    header: "Annuler le rendez-vous",
    modal: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[3] || (_cache[3] = _createTextVNode(" Vous êtes sur le point d'annuler le rendez-vous pour le véhicule ")),
        _createElementVNode("strong", null, _toDisplayString($setup.carStore.focusedCar.owner_last_name) + " | " + _toDisplayString($setup.carStore.focusedCar.model), 1 /* TEXT */),
        _cache[4] || (_cache[4] = _createTextVNode(". "))
      ]),
      _createVNode($setup["Textarea"], {
        id: "background_note",
        modelValue: $setup.carStore.focusedCar.background_note,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = $event => (($setup.carStore.focusedCar.background_note) = $event)),
          _cache[1] || (_cache[1] = (value) => $setup.carStore.updateCarInCharge('background_note', value))
        ],
        rows: "5",
        cols: "30",
        style: {"resize":"none"},
        placeholder: "Écrire un commentaire",
        class: "input-textarea"
      }, null, 8 /* PROPS */, ["modelValue"]),
      _createElementVNode("div", { class: "car-incharge-form-validate" }, [
        _createElementVNode("button", {
          class: "-deletion car-incharge-form-button",
          onClick: $setup.handleDeletion
        }, " Annuler ")
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible"]))
}