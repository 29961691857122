export const stringifyNumber = (x) => {
  const str = x.toString()
  return str.includes(".") ? str.slice(0, str.indexOf(".") + 2) : str
}

export const firstCaseUppered = (str) => {
  return str.charAt(0).toUpperCase()
}

export const formatString = (s) => {
  return s.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
}
