<script setup>
import { ArcElement, Chart, DoughnutController, Legend, Tooltip } from "chart.js"
import { computed, onMounted, ref, watch } from "vue"

import { CircleHelp } from "lucide-vue-next"
import { computeDoneWork, computeWork } from "../../utils/cars.js"

Chart.register(DoughnutController, ArcElement, Legend, Tooltip)

const props = defineProps({
  cars: {
    type: Object,
    required: true,
  },
  delayedWorks: {
    type: Object,
    required: true,
  },
  weekCapacity: {
    type: Object,
    required: true,
  },
  delayAnimation: {
    type: Boolean,
    required: false,
  },
})

const chartRef = ref(null)
const delayAnimation = ref(props.delayAnimation)
const doneWork = computed(() => computeDoneWork(props.cars, props.delayedWorks))
const totalWork = computed(() => computeWork(props.cars, props.delayedWorks))

const options = computed(() => {
  return {
    responsive: true,
    animation: { duration: 700, delay: delayAnimation.value ? 700 : 0 },
    plugins: {
      legend: { display: false },
      datalabels: { display: false },
      tooltip: {
        filter: (tooltipItem) => tooltipItem.label !== "Empty",
        callbacks: {
          label: function(context) {
            const value = context.raw || 0
            const index = context.dataIndex
            const total = Object.values(props.weekCapacity)[index]
            return ` ${Math.floor((value / total) * 1000) / 10} %`
          },
        },
      },
    },
  }
})

const doneWorkPercentage = computed(() => {
  const total = totalWork.value.sheetMetalWork + totalWork.value.paintWork + totalWork.value.mechanicWork
  return total === 0 ? 0 : Math.round((doneWork.value.sheetMetalWork + doneWork.value.paintWork + doneWork.value.mechanicWork) / total * 100)
})
let chartInstance = null

const workDoneDataset = computed(() => {
  const colorSheetMetalWork = getComputedStyle(document.documentElement).getPropertyValue("--color-bg-bar-sheet-metal-work")
  const colorPaintWork = getComputedStyle(document.documentElement).getPropertyValue("--color-bg-bar-painting")
  const colorMechanicWork = getComputedStyle(document.documentElement).getPropertyValue("--color-bg-bar-mechanic")
  return [{
    label: "Dataset",
    data: [
      doneWork.value.sheetMetalWork,
      doneWork.value.paintWork,
      doneWork.value.mechanicWork,
      totalWork.value.sheetMetalWork - doneWork.value.sheetMetalWork + totalWork.value.paintWork - doneWork.value.paintWork + totalWork.value.mechanicWork - doneWork.value.mechanicWork,
    ],
    backgroundColor: [colorSheetMetalWork, colorPaintWork, colorMechanicWork, "transparent"],
    borderWidth: 0,
  }]
})

const updateChart = () => {
  if (chartInstance) {
    chartInstance.data.datasets[0].data = [
      doneWork.value.sheetMetalWork,
      doneWork.value.paintWork,
      doneWork.value.mechanicWork,
      totalWork.value.sheetMetalWork - doneWork.value.sheetMetalWork + totalWork.value.paintWork - doneWork.value.paintWork + totalWork.value.mechanicWork - doneWork.value.mechanicWork,
    ]
    chartInstance.options = options.value
    chartInstance.update()
  }
}

watch([() => props.cars, () => props.delayedWorks], () => {
  updateChart()
}, { deep: true })

onMounted(() => {
  if (!chartRef.value) return
  const ctx = chartRef.value.getContext("2d")
  chartInstance = new Chart(ctx, {
    type: "doughnut",
    data: {
      labels: ["Tôlerie", "Peinture", "Mécanique", "Empty"],
      datasets: workDoneDataset.value,
    },
    options: options.value,
  })

  delayAnimation.value = false
})
</script>

<template>
  <div class="calendar-week-chart">
    <div class="calendar-chart">
      <canvas ref="chartRef" />
    </div>
    <div
      v-if="doneWorkPercentage > 0"
      class="calendar-done-work"
    >
      {{ doneWorkPercentage }} %
    </div>
    <CircleHelp
      v-if="doneWorkPercentage > 0"
      v-tooltip.top="`Le pourcentage au centre représente le travail effectué sur la semaine par rapport aux prévisions. Les couleurs correspondent aux différents avancements par domaine par rapport à la capacité.`"
      class="calendar-done-work-explanation"
    />
  </div>
</template>

<style lang="scss" scoped>
@import "../../../../../ui/static_src/ui/base/mixins";

.calendar-week-chart {
  position: relative;
  width: 100%;
  height: 7.5rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 1rem;
}

.calendar-done-work {
  @include subtitle;
  position: absolute;
  text-align: center;
  width: 5rem;
  opacity: 1;
  transform: translateY(calc(50% + .8rem)) translateX(-.5rem);
  transition: opacity 0.2s ease-in-out;
}

.calendar-chart:hover + .calendar-done-work {
  opacity: 0;
}

.calendar-chart {
  width: 6.5rem;
  height: 6.5rem;
}

canvas {
  width: 100% !important;
  height: 100% !important;
  max-width: 30rem;
}

.calendar-done-work-explanation {
  width: 1rem;
  height: 1rem;
  transform: translateX(1rem);
  color: var(--color-text-secondary);
}
</style>
