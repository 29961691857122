import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "car-followup-tooltip" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['car-followup-point-container', $setup.props.car.complete_file ? '' : '-hidden'])
    }, _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "car-followup-point-large -complete-file" }, null, -1 /* HOISTED */),
      _createTextVNode(" Dossier complet ")
    ]), 2 /* CLASS */),
    _createElementVNode("div", {
      class: _normalizeClass(['car-followup-point-container', $setup.props.car.repair_order ? '' : '-hidden'])
    }, _cache[1] || (_cache[1] = [
      _createElementVNode("div", { class: "car-followup-point-large -repair-order" }, null, -1 /* HOISTED */),
      _createTextVNode(" Ordre de réparation prêt ")
    ]), 2 /* CLASS */),
    _createElementVNode("div", {
      class: _normalizeClass(['car-followup-point-container', $setup.props.car.valid_support ? '' : '-hidden'])
    }, _cache[2] || (_cache[2] = [
      _createElementVNode("div", { class: "car-followup-point-large -valid-support" }, null, -1 /* HOISTED */),
      _createTextVNode(" Prise en charge valide ")
    ]), 2 /* CLASS */)
  ]))
}