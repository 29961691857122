import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "car-incharge-form" }
const _hoisted_2 = { class: "car-incharge-form-block" }
const _hoisted_3 = { class: "car-incharge-form-block -row -no-padding" }
const _hoisted_4 = { class: "car-incharge-form-block" }
const _hoisted_5 = { class: "car-form-subtitle" }
const _hoisted_6 = { class: "car-incharge-form-block -row -no-padding" }
const _hoisted_7 = { class: "car-incharge-form-block -row -no-padding" }
const _hoisted_8 = { class: "car-incharge-form-block" }
const _hoisted_9 = { class: "car-form-subtitle" }
const _hoisted_10 = { class: "car-incharge-form-block" }
const _hoisted_11 = { class: "car-form-subtitle" }
const _hoisted_12 = { class: "car-form-subtitle-icon" }
const _hoisted_13 = ["href"]
const _hoisted_14 = { class: "car-incharge-form-block" }
const _hoisted_15 = { class: "car-form-subtitle" }
const _hoisted_16 = { class: "car-incharge-form-block -row -no-padding" }
const _hoisted_17 = { class: "state-value" }
const _hoisted_18 = { class: "state-value" }
const _hoisted_19 = { class: "car-incharge-form-block" }
const _hoisted_20 = { class: "car-form-subtitle" }
const _hoisted_21 = { class: "car-incharge-form-block -row -no-padding" }
const _hoisted_22 = { class: "car-incharge-form-validate" }
const _hoisted_23 = {
  key: 0,
  class: "pi pi-spin pi-cog loading-icon"
}
const _hoisted_24 = ["disabled"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["RegistrationCar"], {
      car: $props.modelValue,
      onDataExtracted: $setup.handleRegistrationCarExtractedData
    }, null, 8 /* PROPS */, ["car"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["FloatLabel"], { variant: "on" }, {
        default: _withCtx(() => [
          _createVNode($setup["InputText"], {
            id: "registration",
            "model-value": $props.modelValue.registration,
            style: {"width":"100%"},
            "onUpdate:modelValue": _cache[0] || (_cache[0] = (value) => $setup.updateModel('registration', value))
          }, null, 8 /* PROPS */, ["model-value"]),
          _cache[29] || (_cache[29] = _createElementVNode("label", { for: "registration" }, "Plaque d'immatriculation", -1 /* HOISTED */))
        ]),
        _: 1 /* STABLE */
      }),
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["FloatLabel"], { variant: "on" }, {
          default: _withCtx(() => [
            _createVNode($setup["InputText"], {
              id: "brand",
              "model-value": $props.modelValue.brand,
              style: {"min-width":"12.5rem"},
              "onUpdate:modelValue": _cache[1] || (_cache[1] = (value) => $setup.updateModel('brand', value))
            }, null, 8 /* PROPS */, ["model-value"]),
            _cache[30] || (_cache[30] = _createElementVNode("label", { for: "brand" }, "Marque", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["FloatLabel"], { variant: "on" }, {
          default: _withCtx(() => [
            _createVNode($setup["InputText"], {
              id: "model",
              "model-value": $props.modelValue.model,
              style: {"min-width":"12.5rem"},
              required: "",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = (value) => $setup.updateModel('model', value))
            }, null, 8 /* PROPS */, ["model-value"]),
            _cache[31] || (_cache[31] = _createElementVNode("label", { for: "model" }, "Modèle", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _createVNode($setup["FloatLabel"], { variant: "on" }, {
        default: _withCtx(() => [
          _createVNode($setup["DatePicker"], {
            id: "first_registration_date",
            "model-value": $props.modelValue.first_registration_date,
            style: {"width":"100%"},
            "date-format": "dd/mm/yy",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = (value) => $setup.updateModel('first_registration_date', value))
          }, null, 8 /* PROPS */, ["model-value"]),
          _cache[32] || (_cache[32] = _createElementVNode("label", { for: "first_registration_date" }, "Date de 1ère immatriculation", -1 /* HOISTED */))
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode($setup["FloatLabel"], { variant: "on" }, {
        default: _withCtx(() => [
          _createVNode($setup["InputText"], {
            id: "foreground_note",
            "model-value": $props.modelValue.foreground_note,
            style: {"width":"100%"},
            "onUpdate:modelValue": _cache[4] || (_cache[4] = (value) => $setup.updateModel('foreground_note', value))
          }, null, 8 /* PROPS */, ["model-value"]),
          _cache[33] || (_cache[33] = _createElementVNode("label", { for: "foreground_note" }, "Note", -1 /* HOISTED */))
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode($setup["UserRound"], { size: "24" }),
        _cache[34] || (_cache[34] = _createTextVNode(" Propriétaire "))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode($setup["FloatLabel"], { variant: "on" }, {
          default: _withCtx(() => [
            _createVNode($setup["InputText"], {
              id: "owner_first_name",
              "model-value": $props.modelValue.owner_first_name,
              style: {"min-width":"12.5rem"},
              "onUpdate:modelValue": _cache[5] || (_cache[5] = (value) => $setup.updateModel('owner_first_name', value))
            }, null, 8 /* PROPS */, ["model-value"]),
            _cache[35] || (_cache[35] = _createElementVNode("label", { for: "owner_first_name" }, "Prénom", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["FloatLabel"], { variant: "on" }, {
          default: _withCtx(() => [
            _createVNode($setup["InputText"], {
              id: "owner_last_name",
              "model-value": $props.modelValue.owner_last_name,
              style: {"min-width":"12.5rem"},
              "onUpdate:modelValue": _cache[6] || (_cache[6] = (value) => $setup.updateModel('owner_last_name', value))
            }, null, 8 /* PROPS */, ["model-value"]),
            _cache[36] || (_cache[36] = _createElementVNode("label", { for: "owner_last_name" }, "Nom", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode($setup["FloatLabel"], { variant: "on" }, {
          default: _withCtx(() => [
            _createVNode($setup["InputText"], {
              id: "owner_phone_number",
              "model-value": $props.modelValue.owner_phone_number,
              style: {"min-width":"12.5rem"},
              "onUpdate:modelValue": _cache[7] || (_cache[7] = (value) => $setup.updateModel('owner_phone_number', value))
            }, null, 8 /* PROPS */, ["model-value"]),
            _cache[37] || (_cache[37] = _createElementVNode("label", { for: "owner_phone_number" }, "Numéro de téléphone 1", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["FloatLabel"], { variant: "on" }, {
          default: _withCtx(() => [
            _createVNode($setup["InputText"], {
              id: "owner_phone_number_2",
              "model-value": $props.modelValue.owner_phone_number_2,
              style: {"min-width":"12.5rem"},
              "onUpdate:modelValue": _cache[8] || (_cache[8] = (value) => $setup.updateModel('owner_phone_number_2', value))
            }, null, 8 /* PROPS */, ["model-value"]),
            _cache[38] || (_cache[38] = _createElementVNode("label", { for: "owner_phone_number_2" }, "Numéro de téléphone 2", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _createVNode($setup["FloatLabel"], { variant: "on" }, {
        default: _withCtx(() => [
          _createVNode($setup["InputText"], {
            id: "owner_email",
            "model-value": $props.modelValue.owner_email,
            style: {"width":"100%"},
            "onUpdate:modelValue": _cache[9] || (_cache[9] = (value) => $setup.updateModel('owner_email', value))
          }, null, 8 /* PROPS */, ["model-value"]),
          _cache[39] || (_cache[39] = _createElementVNode("label", { for: "owner_phone_number_2" }, "Email", -1 /* HOISTED */))
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode($setup["FloatLabel"], { variant: "on" }, {
        default: _withCtx(() => [
          _createVNode($setup["InputText"], {
            id: "owner_address",
            "model-value": $props.modelValue.owner_address,
            style: {"width":"100%"},
            "onUpdate:modelValue": _cache[10] || (_cache[10] = (value) => $setup.updateModel('owner_address', value))
          }, null, 8 /* PROPS */, ["model-value"]),
          _cache[40] || (_cache[40] = _createElementVNode("label", { for: "owner_address" }, "Adresse", -1 /* HOISTED */))
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createVNode($setup["Folder"], { size: "24" }),
        _cache[41] || (_cache[41] = _createTextVNode(" Dossier "))
      ]),
      _createElementVNode("button", {
        class: "car-form-checkbox",
        onClick: _cache[11] || (_cache[11] = $event => ($setup.updateModel('complete_file', !$props.modelValue.complete_file, $event)))
      }, [
        _createVNode($setup["Checkbox"], {
          "model-value": $props.modelValue.complete_file,
          "input-id": "complete_file",
          binary: true,
          class: "checkbox-car-incharge"
        }, null, 8 /* PROPS */, ["model-value"]),
        _cache[42] || (_cache[42] = _createElementVNode("label", {
          for: "complete_file",
          style: {"cursor":"pointer","color":"var(--color-text-secondary)"}
        }, "Dossier complet", -1 /* HOISTED */))
      ]),
      _createElementVNode("button", {
        class: "car-form-checkbox",
        onClick: _cache[12] || (_cache[12] = $event => ($setup.updateModel('repair_order', !$props.modelValue.repair_order, $event)))
      }, [
        _createVNode($setup["Checkbox"], {
          "model-value": $props.modelValue.repair_order,
          "input-id": "repair_order",
          binary: true,
          class: "checkbox-car-incharge"
        }, null, 8 /* PROPS */, ["model-value"]),
        _cache[43] || (_cache[43] = _createElementVNode("label", {
          for: "repair_order",
          style: {"cursor":"pointer","color":"var(--color-text-secondary)"}
        }, "Ordre de réparation prêt", -1 /* HOISTED */))
      ]),
      _createElementVNode("button", {
        class: "car-form-checkbox",
        onClick: _cache[13] || (_cache[13] = $event => ($setup.updateModel('valid_support', !$props.modelValue.valid_support, $event)))
      }, [
        _createVNode($setup["Checkbox"], {
          "model-value": $props.modelValue.valid_support,
          "input-id": "valid_support",
          binary: true,
          class: "checkbox-car-incharge"
        }, null, 8 /* PROPS */, ["model-value"]),
        _cache[44] || (_cache[44] = _createElementVNode("label", {
          for: "valid_support",
          style: {"cursor":"pointer","color":"var(--color-text-secondary)"}
        }, "Prise en charge valide", -1 /* HOISTED */))
      ]),
      _createElementVNode("button", {
        class: "car-form-checkbox",
        onClick: _cache[14] || (_cache[14] = $event => ($setup.updateModel('from_tow_truck', !$props.modelValue.from_tow_truck, $event)))
      }, [
        _createVNode($setup["Checkbox"], {
          "model-value": $props.modelValue.from_tow_truck,
          "input-id": "from_tow_truck",
          binary: true,
          class: "checkbox-car-incharge"
        }, null, 8 /* PROPS */, ["model-value"]),
        _cache[45] || (_cache[45] = _createElementVNode("label", {
          for: "from_tow_truck",
          style: {"cursor":"pointer","color":"var(--color-text-secondary)"}
        }, "Arrivé par dépannage", -1 /* HOISTED */))
      ]),
      _createElementVNode("button", {
        class: "car-form-checkbox",
        onClick: _cache[15] || (_cache[15] = $event => ($setup.updateModel('is_seriously_damaged', !$props.modelValue.is_seriously_damaged, $event)))
      }, [
        _createVNode($setup["Checkbox"], {
          "model-value": $props.modelValue.is_seriously_damaged,
          "input-id": "is_seriously_damaged",
          binary: true,
          class: "checkbox-car-incharge"
        }, null, 8 /* PROPS */, ["model-value"]),
        _cache[46] || (_cache[46] = _createElementVNode("label", {
          for: "is_seriously_damaged",
          style: {"cursor":"pointer","color":"var(--color-text-secondary)"}
        }, "Véhicule gravement endommagé", -1 /* HOISTED */))
      ]),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        (_openBlock(), _createElementBlock("svg", _hoisted_12, [
          _createElementVNode("use", {
            href: $setup.svgLink('cars', 'car-replacement')
          }, null, 8 /* PROPS */, _hoisted_13)
        ])),
        _cache[47] || (_cache[47] = _createTextVNode(" Remplacement "))
      ]),
      _createElementVNode("button", {
        class: "car-form-checkbox",
        onClick: _cache[16] || (_cache[16] = $event => ($setup.updateModel('need_replacement', !$props.modelValue.need_replacement, $event)))
      }, [
        _createVNode($setup["Checkbox"], {
          "model-value": $props.modelValue.need_replacement,
          "input-id": "need_replacement",
          binary: true,
          class: "checkbox-car-incharge"
        }, null, 8 /* PROPS */, ["model-value"]),
        _cache[48] || (_cache[48] = _createElementVNode("label", {
          for: "need_replacement",
          style: {"cursor":"pointer","color":"var(--color-text-secondary)"}
        }, "Le propriétaire souhaite un véhicule de remplacement", -1 /* HOISTED */))
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", _hoisted_15, [
        _createVNode($setup["Clipboard"], { size: "24" }),
        _cache[49] || (_cache[49] = _createTextVNode(" Travail "))
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createVNode($setup["FloatLabel"], { variant: "on" }, {
          default: _withCtx(() => [
            _createVNode($setup["Select"], {
              id: "state",
              "model-value": $setup.currentStateName,
              options: $setup.STATES_LIST.map(s => s.name),
              style: {"width":"12rem"},
              onValueChange: _cache[17] || (_cache[17] = (value) => $setup.updateModel('state', $setup.STATES_LIST.find(s => s.name === value).value))
            }, {
              value: _withCtx(() => [
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("div", {
                    class: _normalizeClass(`car__card-state -${$props.modelValue.state}`)
                  }, null, 2 /* CLASS */),
                  _createTextVNode(" " + _toDisplayString($setup.currentStateName), 1 /* TEXT */)
                ])
              ]),
              option: _withCtx(({ option }) => [
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", {
                    class: _normalizeClass(`car__card-state -${$setup.STATES_LIST.find(s => s.name === option).value}`)
                  }, null, 2 /* CLASS */),
                  _createTextVNode(" " + _toDisplayString(option), 1 /* TEXT */)
                ])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["model-value", "options"]),
            _cache[50] || (_cache[50] = _createElementVNode("label", { for: "state" }, "État", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        }),
        ($props.modelValue.state === 'breakage')
          ? (_openBlock(), _createBlock($setup["FloatLabel"], {
              key: 0,
              variant: "on"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["DatePicker"], {
                  id: "breakage_date",
                  "model-value": $props.modelValue.breakage_date,
                  style: {"min-width":"13rem"},
                  "date-format": "dd/mm/yy",
                  onValueChange: _cache[18] || (_cache[18] = (value) => $setup.updateModel('breakage_date', value))
                }, null, 8 /* PROPS */, ["model-value"]),
                _cache[51] || (_cache[51] = _createElementVNode("label", { for: "breakage_date" }, "Date de départ à la casse", -1 /* HOISTED */))
              ]),
              _: 1 /* STABLE */
            }))
          : ($props.modelValue.state === 'expertise')
            ? (_openBlock(), _createBlock($setup["FloatLabel"], {
                key: 1,
                variant: "on"
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["DatePicker"], {
                    id: "expertise_date",
                    "model-value": $props.modelValue.expertise_date,
                    style: {"min-width":"13rem"},
                    "date-format": "dd/mm/yy",
                    onValueChange: _cache[19] || (_cache[19] = (value) => $setup.updateModel('expertise_date', value))
                  }, null, 8 /* PROPS */, ["model-value"]),
                  _cache[52] || (_cache[52] = _createElementVNode("label", { for: "expertise_date" }, "Date de l'expertise", -1 /* HOISTED */))
                ]),
                _: 1 /* STABLE */
              }))
            : ($props.modelValue.state === 'waiting')
              ? (_openBlock(), _createBlock($setup["FloatLabel"], {
                  key: 2,
                  variant: "on"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["DatePicker"], {
                      id: "waiting_date",
                      "model-value": $props.modelValue.waiting_date,
                      style: {"min-width":"13rem"},
                      "date-format": "dd/mm/yy",
                      onValueChange: _cache[20] || (_cache[20] = (value) => $setup.updateModel('waiting_date', value))
                    }, null, 8 /* PROPS */, ["model-value"]),
                    _cache[53] || (_cache[53] = _createElementVNode("label", { for: "waiting_date" }, "En attente depuis", -1 /* HOISTED */))
                  ]),
                  _: 1 /* STABLE */
                }))
              : (_openBlock(), _createBlock($setup["FloatLabel"], {
                  key: 3,
                  variant: "on"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["DatePicker"], {
                      id: "start_work",
                      "model-value": $props.modelValue.start_work,
                      style: {"min-width":"13rem"},
                      "date-format": "dd/mm/yy",
                      onValueChange: _cache[21] || (_cache[21] = (value) => $setup.updateModel('start_work', value))
                    }, null, 8 /* PROPS */, ["model-value"]),
                    _cache[54] || (_cache[54] = _createElementVNode("label", { for: "start_work" }, "Date de début des réparations", -1 /* HOISTED */))
                  ]),
                  _: 1 /* STABLE */
                }))
      ]),
      ($props.modelValue.state === 'breakage')
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "car-form-checkbox",
            onClick: _cache[22] || (_cache[22] = $event => ($setup.updateModel('wreck_delivered', !$props.modelValue.wreck_delivered, $event)))
          }, [
            _createVNode($setup["Checkbox"], {
              "model-value": $props.modelValue.wreck_delivered,
              "input-id": "wreck_delivered",
              binary: true,
              class: "checkbox-car-incharge"
            }, null, 8 /* PROPS */, ["model-value"]),
            _cache[55] || (_cache[55] = _createElementVNode("label", {
              for: "wreck_delivered",
              style: {"cursor":"pointer","color":"var(--color-text-secondary)"}
            }, "Épave livrée", -1 /* HOISTED */))
          ]))
        : _createCommentVNode("v-if", true),
      (['todo', 'coming', 'repairing', 'reassembly', 'finished', 'delivered'].includes($props.modelValue.state))
        ? (_openBlock(), _createBlock($setup["FloatLabel"], {
            key: 1,
            variant: "on"
          }, {
            default: _withCtx(() => [
              _createVNode($setup["DatePicker"], {
                id: "due_date",
                "model-value": $props.modelValue.due_date,
                style: {"width":"100%"},
                "date-format": "dd/mm/yy",
                "min-date": new Date(),
                onValueChange: _cache[23] || (_cache[23] = (value) => $setup.updateModel('due_date', value))
              }, null, 8 /* PROPS */, ["model-value", "min-date"]),
              _cache[56] || (_cache[56] = _createElementVNode("label", { for: "due_date" }, "Date souhaitée de récupération du véhicule", -1 /* HOISTED */))
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", _hoisted_20, [
          _createVNode($setup["Clock"], { size: "24" }),
          _cache[57] || (_cache[57] = _createTextVNode(" Charge horaire "))
        ]),
        _createElementVNode("div", _hoisted_21, [
          _createVNode($setup["FloatLabel"], { variant: "on" }, {
            default: _withCtx(() => [
              _createVNode($setup["InputNumber"], {
                id: "sheet_metal_work_hours",
                "model-value": $props.modelValue.sheet_metal_work_hours,
                "min-fraction-digits": 1,
                style: {"width":"8rem"},
                type: "number",
                locale: "en-US",
                "onUpdate:modelValue": _cache[24] || (_cache[24] = (value) => $setup.updateModel('sheet_metal_work_hours', value))
              }, null, 8 /* PROPS */, ["model-value"]),
              _cache[58] || (_cache[58] = _createElementVNode("label", { for: "sheet_metal_work_hours" }, "Tôlerie", -1 /* HOISTED */))
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode($setup["FloatLabel"], { variant: "on" }, {
            default: _withCtx(() => [
              _createVNode($setup["InputNumber"], {
                id: "painting_hours",
                "model-value": $props.modelValue.painting_hours,
                "min-fraction-digits": 1,
                style: {"width":"8rem"},
                type: "number",
                locale: "en-US",
                "onUpdate:modelValue": _cache[25] || (_cache[25] = (value) => $setup.updateModel('painting_hours', value))
              }, null, 8 /* PROPS */, ["model-value"]),
              _cache[59] || (_cache[59] = _createElementVNode("label", { for: "painting_hours" }, "Peinture", -1 /* HOISTED */))
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode($setup["FloatLabel"], { variant: "on" }, {
            default: _withCtx(() => [
              _createVNode($setup["InputNumber"], {
                id: "mechanic_hours",
                "model-value": $props.modelValue.mechanic_hours,
                "min-fraction-digits": 1,
                style: {"width":"8rem"},
                type: "number",
                locale: "en-US",
                "onUpdate:modelValue": _cache[26] || (_cache[26] = (value) => $setup.updateModel('mechanic_hours', value))
              }, null, 8 /* PROPS */, ["model-value"]),
              _cache[60] || (_cache[60] = _createElementVNode("label", { for: "mechanic_hours" }, "Mécanique", -1 /* HOISTED */))
            ]),
            _: 1 /* STABLE */
          })
        ])
      ])
    ]),
    _createVNode($setup["Textarea"], {
      id: "background_note",
      "model-value": $props.modelValue.background_note,
      rows: "5",
      cols: "30",
      style: {"resize":"none","width":"100%"},
      placeholder: "Écrire un commentaire",
      "onUpdate:modelValue": _cache[27] || (_cache[27] = (value) => $setup.updateModel('background_note', value))
    }, null, 8 /* PROPS */, ["model-value"]),
    _createElementVNode("div", _hoisted_22, [
      ($props.loading)
        ? (_openBlock(), _createElementBlock("i", _hoisted_23))
        : (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "car-incharge-form-button",
            disabled: !$setup.getStateDate($props.modelValue) || !$props.modelValue.model || !$props.modelValue.owner_last_name,
            onClick: _cache[28] || (_cache[28] = (...args) => ($props.handleValidate && $props.handleValidate(...args)))
          }, " Valider ", 8 /* PROPS */, _hoisted_24))
    ])
  ]))
}