<script setup>
import { BarController, BarElement, CategoryScale, Chart, Legend, LinearScale, Title, Tooltip } from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { computed, onMounted, ref, watch } from "vue"

import { stringifyNumber } from "../../utils/string.js"

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, BarController, ChartDataLabels)

const props = defineProps({
  work: {
    type: Object,
    required: true,
  },
  capacity: {
    type: Object,
    required: true,
  },
  fontSizeValue: {
    type: String,
    required: true,
  },
  delayAnimation: {
    type: Boolean,
    default: false,
  },
})

const renderKey = ref(0)
const chartRef = ref(null)
const delayAnimation = ref(props.delayAnimation)
let chartInstance = null

const excess = computed(() => ({
  sheetMetalWork: Math.max(0, props.work.sheetMetalWork - props.capacity.sheetMetalWork),
  paintWork: Math.max(0, props.work.paintWork - props.capacity.paintWork),
  mechanicWork: Math.max(0, props.work.mechanicWork - props.capacity.mechanicWork),
}))

const maxValue = computed(() => Math.max(Object.values(props.capacity).reduce((acc, hour) => Math.max(acc, hour), 0), Object.values(props.work).reduce((acc, hour) => Math.max(acc, hour), 0)))

const buildFontSize = () => {
  if (props.fontSizeValue === "small") return 12

  const breakpointS = getComputedStyle(document.documentElement).getPropertyValue("--breakpoint-s")
  const breakpointMobile = getComputedStyle(document.documentElement).getPropertyValue("--breakpoint-mobile")

  if (window.matchMedia(`(max-width: ${breakpointS})`).matches) return 14
  if (window.matchMedia(`(max-width: ${breakpointMobile})`).matches) return 12
  return 16
}

const labelsColor = computed(() => {
  // eslint-disable-next-line no-unused-expressions
  renderKey.value
  const colorTextSecondary = getComputedStyle(document.documentElement).getPropertyValue("--color-text-secondary")
  const colorTextPrimary = getComputedStyle(document.documentElement).getPropertyValue("--color-text-primary")

  if (props.fontSizeValue === "small") return colorTextSecondary
  return colorTextPrimary
})

const dataLabelsColor = computed(() => {
  // eslint-disable-next-line no-unused-expressions
  renderKey.value
  return getComputedStyle(document.documentElement).getPropertyValue("--color-text-primary")
})

const extraOptions = { grid: { display: false }, border: { display: false } }
const options = computed(() => ({
  responsive: true,
  animation: { duration: 700, delay: delayAnimation.value ? 700 : 0 },
  ticks: { font: { size: buildFontSize() } },
  plugins: {
    tooltip: {
      enabled: true,
      intersect: false,
      mode: "point",
      position: "nearest",
      callbacks: {
        title: function(tooltipItems) {
          return tooltipItems[0].label
        },
        label: function(context) {
          const label = context.dataset.label
          const capacity = Object.values(props.capacity)[context.dataIndex]
          if (label === "Capacité") return `${label}: ${stringifyNumber(capacity)}`
          const hours = Object.values(props.work)[context.dataIndex]
          if (label === "Heures") return `${label}: ${stringifyNumber(hours)}`
          const excessValue = Object.values(excess.value)[context.dataIndex]
          return `${label}: ${stringifyNumber(excessValue)}`
        },
      },
    },
    legend: { display: false },
    datalabels: {
      display: true,
      color: dataLabelsColor.value,
      anchor: "center",
      align: "center",
      formatter: function(_, context) {
        const index = context.dataIndex
        const label = context.dataset.label
        const value = Object.values(props.work)[index]
        const capacity = Object.values(props.capacity)[index]
        if (capacity === 0) return null
        const percentage = stringifyNumber((value / capacity) * 100)
        if (label === "Dépassement") {
          if (value > capacity) return `${percentage}%`
          return null
        }
        if (label === "Heures") return null
        if (label === "Capacité") {
          if (value <= capacity) return `${percentage}%`
          return null
        }
      },
      font: {
        weight: "bold",
        size: buildFontSize(),
      },
    },
  },
  indexAxis: "y",
  maintainAspectRatio: false,
  scales: {
    y: { stacked: true, beginAtZero: true, ticks: { color: labelsColor.value }, ...extraOptions },
    x: { display: false, max: maxValue.value, ...extraOptions },
  },
}))
const BLUE_COLOR = "rgb(23, 162, 184)"
const RED_COLOR = "rgb(255, 0, 0)"

const computedWork = (work) => {
  const capacity = props.capacity[work]
  const workValue = props.work[work]
  const excessValue = excess.value[work]

  if (capacity === 0) {
    const normalization = props.fontSizeValue === "small" ? 4 : 2
    const offset = props.fontSizeValue === "small" ? 50 : 60
    const normalizedwork = workValue ? Math.min(offset, maxValue.value / normalization) : 0
    return { work: 0, excess: normalizedwork, capacity: normalizedwork }
  }

  return { work: workValue - excessValue, excess: workValue, capacity: Math.max(capacity, maxValue.value / 4) }
}

const workToDoDataset = computed(() => {
  const sheetMetalWork = computedWork("sheetMetalWork")
  const paintWork = computedWork("paintWork")
  const mechanicWork = computedWork("mechanicWork")
  return [{
    label: "Heures",
    data: [sheetMetalWork.work, paintWork.work, mechanicWork.work],
    backgroundColor: BLUE_COLOR,
    borderWidth: 1,
    borderRadius: { topLeft: 2, topRight: 0, bottomLeft: 2, bottomRight: 0 },
    borderColor: BLUE_COLOR,
    order: 1,
  },
  {
    label: "Dépassement",
    data: [sheetMetalWork.excess, paintWork.excess, mechanicWork.excess],
    backgroundColor: RED_COLOR,
    borderWidth: 1,
    borderColor: RED_COLOR,
    order: 2,
    borderRadius: 2,
  },
  {
    label: "Capacité",
    data: [sheetMetalWork.capacity, paintWork.capacity, mechanicWork.capacity],
    backgroundColor: "transparent",
    borderColor: BLUE_COLOR,
    borderWidth: 1,
    borderRadius: 2,
    borderSkipped: false,
    order: 3,
  }]
})

const updateChart = () => {
  if (chartInstance) {
    const sheetMetalWork = computedWork("sheetMetalWork")
    const paintWork = computedWork("paintWork")
    const mechanicWork = computedWork("mechanicWork")

    // Update datasets
    chartInstance.data.datasets[0].data = [sheetMetalWork.work, paintWork.work, mechanicWork.work]
    chartInstance.data.datasets[1].data = [sheetMetalWork.excess, paintWork.excess, mechanicWork.excess]
    chartInstance.data.datasets[2].data = [sheetMetalWork.capacity, paintWork.capacity, mechanicWork.capacity]

    // Update options
    chartInstance.options = options.value

    // Update the chart
    chartInstance.update()
  }
}

watch(() => props.work, () => {
  updateChart()
}, { deep: true })

onMounted(() => {
  const ctx = chartRef.value.getContext("2d")
  chartInstance = new Chart(ctx, {
    type: "bar",
    data: {
      labels: ["Tôlerie", "Peinture", "Mécanique"],
      datasets: workToDoDataset.value,
    },
    options: options.value,
    plugins: [ChartDataLabels],
  })

  document.addEventListener("theme-change", () => {
    renderKey.value += 1
    updateChart()
  })
  delayAnimation.value = false
})
</script>

<template>
  <div class="calendar-chart">
    <canvas ref="chartRef" />
  </div>
</template>

<style lang="scss" scoped>
.calendar-chart {
  width: 100%;
  height: 100%;
}

canvas {
    width: 100% !important;
    height: 100% !important;
    max-width: 30rem;
}
</style>
