<script setup>
import { asyncComputed } from "@vueuse/core/index.cjs"
import { orderBy } from "lodash-es"
import { ArrowBigRight, Check, TriangleAlert, X } from "lucide-vue-next"
import { storeToRefs } from "pinia"
import { useToast } from "primevue"
import AutoComplete from "primevue/autocomplete"
import Checkbox from "primevue/checkbox"
import DatePicker from "primevue/datepicker"
import Dialog from "primevue/dialog"
import FloatLabel from "primevue/floatlabel"
import { computed, ref, watch } from "vue"

import ReplacementPartial from "../../../../../../cars/static_src/cars/components/_partials/Replacement.vue"

import { createReplacement, getMaintenanceTimeOfWeek, getReplacementsOfWeek, updateCarInCharge, updateReplacement } from "../../../services/api.js"
import { useCalendarStore } from "../../../stores/calendar.js"
import { useCarStore } from "../../../stores/car.js"
import { getCalendarDate } from "../../../utils/cars.js"
import { getCurrentMonday, getIsoString, getNextFriday, getShortDate } from "../../../utils/date.js"
import { formatString } from "../../../utils/string.js"

const calendarStore = useCalendarStore()
const carStore = useCarStore()
const toast = useToast()
const { focusedCar } = storeToRefs(carStore)

const query = ref("")
const replacement = ref({ start_date: "", end_date: "", replacement_car: null, finished: false })
const minDate = ref(null)
const conflictReplacements = ref([])
const conflictMaintenanceTimes = ref([])
const autoCompleteRef = ref(null)
const filteredReplacementCars = ref(calendarStore.replacementCars)
const weekReplacement = asyncComputed(async() => {
  const stateDate = getCalendarDate({ type: "car", car: focusedCar.value })
  if (!stateDate || !calendarStore.replacementModalOpen) return []
  const monday = getIsoString(getCurrentMonday(stateDate))
  const { data } = await getReplacementsOfWeek(monday, window.CALENDAR_ENV.company_pk)
  return data
}, [])
const weekMaintenanceTimes = asyncComputed(async() => {
  const stateDate = getCalendarDate({ type: "car", car: focusedCar.value })
  if (!stateDate || !calendarStore.replacementModalOpen) return []
  const monday = getIsoString(getCurrentMonday(stateDate))
  const { data } = await getMaintenanceTimeOfWeek(monday, window.CALENDAR_ENV.company_pk)
  return data
}, [])
const replacementsSuggestions = computed(() => {
  const restuls = filteredReplacementCars.value.map(car => {
    const currentReplacement = weekReplacement.value.find(replacement => replacement.replacement_car.id === car.id)
    const currentMaintenanceTime = weekMaintenanceTimes.value.find(m => m.replacement_car.id === car.id)

    let startDate
    let endDate
    if (currentReplacement && currentMaintenanceTime) {
      startDate = new Date(currentReplacement.start_date) < new Date(currentMaintenanceTime.start_date) ? new Date(currentReplacement.start_date) : new Date(currentMaintenanceTime.start_date)
      endDate = new Date(currentReplacement.end_date) > new Date(currentMaintenanceTime.end_date) ? new Date(currentReplacement.end_date) : new Date(currentMaintenanceTime.end_date)
    } else if (currentReplacement) {
      startDate = new Date(currentReplacement.start_date)
      endDate = new Date(currentReplacement.end_date)
    } else if (currentMaintenanceTime) {
      startDate = new Date(currentMaintenanceTime.start_date)
      endDate = new Date(currentMaintenanceTime.end_date)
    }

    return {
      ...car,
      start_date: startDate ? getShortDate(startDate) : "",
      end_date: endDate ? getShortDate(endDate) : "",
      score: (hasMatchingInsurances(car.insurance_groups) ? 2 : 0) + (car.insurance_groups.length === 0 ? 0 : car.insurance_groups[0].priority),
    }
  })
  return orderBy(restuls, ["score"], ["desc"])
})

watch(query, () => {
  const formatedQuery = formatString(query.value)
  filteredReplacementCars.value = calendarStore.replacementCars.filter(car => {
    return formatString(car.registration).includes(formatedQuery) || formatString(car.model).includes(formatedQuery) || formatString(car.brand).includes(formatedQuery)
  })
})

watch(() => calendarStore.replacementCars, (newCars) => {
  filteredReplacementCars.value = newCars
})

watch([focusedCar], () => {
  conflictReplacements.value = []
  conflictMaintenanceTimes.value = []

  minDate.value = getCalendarDate({ type: "car", car: focusedCar.value })
  replacement.value = focusedCar.value.replacements.length === 0
    ? {
        start_date: minDate.value,
        end_date: getNextFriday(minDate.value),
        replacement_car: null,
        finished: false,
      }
    : { ...focusedCar.value.replacements[0], start_date: new Date(focusedCar.value.replacements[0].start_date), end_date: new Date(focusedCar.value.replacements[0].end_date) }
  query.value = focusedCar.value.replacements.length === 0 ? "" : `${replacement.value.replacement_car.registration} - ${replacement.value.replacement_car.brand} - ${replacement.value.replacement_car.model}`
  checkConflict()
})

watch([replacement], () => {
  checkConflict()
})

const onFocus = () => {
  autoCompleteRef.value.show()
}

const hasMatchingInsurances = (groups) => {
  return groups.filter(group => {
    const groupInsurancesIds = group.insurances.map(i => i.id)
    return focusedCar.value.insurances.filter(i => groupInsurancesIds.includes(i.id)).length > 0
  }).length > 0
}

const handleSelect = (event) => {
  query.value = `${event.value.registration} - ${event.value.brand} - ${event.value.model}`
  replacement.value.replacement_car = event.value
  checkConflict()
}

const checkConflict = async() => {
  if (!replacement.value.replacement_car || !calendarStore.replacementModalOpen) return
  const firstMonday = getCurrentMonday(replacement.value.start_date)
  const lastMonday = getCurrentMonday(replacement.value.end_date)

  const replacements = []
  const maintenanceTimes = []
  for (let date = firstMonday; date.getTime() <= lastMonday.getTime(); date.setDate(date.getDate() + 7)) {
    const replacementResponse = await getReplacementsOfWeek(getIsoString(date), window.CALENDAR_ENV.company_pk)
    const maintenanceTimeResponse = await getMaintenanceTimeOfWeek(getIsoString(date), window.CALENDAR_ENV.company_pk)
    replacements.push(...replacementResponse.data)
    maintenanceTimes.push(...maintenanceTimeResponse.data)
  }

  conflictReplacements.value = replacements.filter(r => {
    if (r.id === replacement.value.id) return false
    const startDate = new Date(r.start_date).getTime()
    const endDate = new Date(r.end_date).getTime()
    const replacementStartDate = new Date(replacement.value.start_date).getTime()
    const replacementEndDate = new Date(replacement.value.end_date).getTime()
    return r.replacement_car.id === replacement.value.replacement_car?.id &&
    ((startDate > replacementStartDate && startDate < replacementEndDate) ||
    (endDate > replacementStartDate && endDate < replacementEndDate) ||
    (startDate < replacementStartDate && endDate > replacementEndDate))
  })

  conflictMaintenanceTimes.value = maintenanceTimes.filter(m => {
    const startDate = new Date(m.start_date).getTime()
    const endDate = new Date(m.end_date).getTime()
    const replacementStartDate = new Date(replacement.value.start_date).getTime()
    const replacementEndDate = new Date(replacement.value.end_date).getTime()
    return m.replacement_car.id === replacement.value.replacement_car?.id &&
    ((startDate > replacementStartDate && startDate < replacementEndDate) ||
    (endDate > replacementStartDate && endDate < replacementEndDate) ||
    (startDate < replacementStartDate && endDate > replacementEndDate))
  })
}

const handleValidate = () => {
  if (!replacement.value.start_date || !replacement.value.end_date || !replacement.value.replacement_car || conflictReplacements.value.length > 0 || conflictMaintenanceTimes.value.length > 0) return
  calendarStore.replacementModalOpen = false
  const car = calendarStore.cars.find(car => car.id === focusedCar.value.id)
  if (car) {
    calendarStore.cars.find(car => car.id === focusedCar.value.id).replacements = [replacement.value]
  }
  const delayedWorks = calendarStore.delayedWorks.filter(work => work.car.id === focusedCar.value.id)
  delayedWorks.forEach(work => {
    calendarStore.delayedWorks.find(w => w.id === work.id).car.replacements = [replacement.value]
  })

  const obj = {
    ...replacement.value,
    start_date: getIsoString(replacement.value.start_date),
    end_date: getIsoString(replacement.value.end_date),
    replacement_car_id: replacement.value.replacement_car.id,
    car_incharge_id: focusedCar.value.id,
    company_id: window.CALENDAR_ENV.company_pk,
  }
  if (!replacement.value.id) {
    createReplacement(obj).then(() => {
      toast.add({
        severity: "success",
        summary: "Prêt enregistré",
        detail: `Le véhicule de remplacement ${replacement.value.replacement_car.registration} - ${replacement.value.replacement_car.brand} - ${replacement.value.replacement_car.model} a été enregistré pour ${focusedCar.value.owner_last_name} | ${focusedCar.value.model} du ${getShortDate(replacement.value.start_date)} au ${getShortDate(replacement.value.end_date)}`,
        life: 5000,
      })
      updateCarInCharge(focusedCar.value)
    })
  } else updateReplacement(obj)
}

const reset = () => {
  conflictReplacements.value = []
  conflictMaintenanceTimes.value = []
  query.value = ""
}
</script>

<template>
  <Dialog
    v-model:visible="calendarStore.replacementModalOpen"
    modal
    @after-hide="reset"
  >
    <template #header>
      <div
        v-if="!replacement.id"
        class="replacement-header"
      >
        <span>Fournir un véhicule de remplacement pour</span><br> {{ focusedCar.model }} | {{ focusedCar.owner_last_name }}
      </div>
      <div
        v-else
        class="replacement-header"
      >
        <span>Modifier l'emprunt pour</span><br> {{ focusedCar.model }} | {{ focusedCar.owner_last_name }}
      </div>
    </template>
    <div class="replacement-container">
      <FloatLabel variant="on">
        <AutoComplete
          id="car_replacement"
          ref="autoCompleteRef"
          v-model="query"
          style="width: 30rem;"
          :suggestions="replacementsSuggestions"
          @focus="onFocus"
          @item-select="handleSelect"
        >
          <template #header>
            <div class="replacement-car-header">
              <div style="width: 15rem;">
                Véhicules
              </div>
              <div style="width: 5rem; text-align: center;">
                Groupes
              </div>
              <div style="width: 10rem; text-align: center;">
                Prise en charge
              </div>
              <div style="width: 10rem; text-align: center;">
                Indisponibilité
              </div>
            </div>
          </template>
          <template #option="slotProps">
            <div class="replacement-car-selecter">
              <span style="width: 15rem;">{{ slotProps.option.registration }} - {{ slotProps.option.brand }} - {{ slotProps.option.model }}</span>
              <div
                class="replacement-car-insurance-groups"
                style="width: 5rem; text-align: center;"
              >
                <div
                  v-for="(group, index) in slotProps.option.insurance_groups"
                  :key="index"
                  v-tooltip.bottom="group.name"
                  :class="`car__card-state -${group.color}`"
                />
              </div>
              <div
                class="replacement-car-valid"
                style="width: 10rem;"
              >
                <Check
                  v-if="hasMatchingInsurances(slotProps.option.insurance_groups)"
                  size="18"
                  class="replacement-car-valid-icon"
                />
                <X
                  v-else
                  size="18"
                  class="replacement-car-invalid-icon"
                />
              </div>
              <div
                v-if="slotProps.option.start_date"
                class="replacement-car-borrow-dates"
                style="width: 10rem;"
              >
                <div class="replacement-car-borrow-date">
                  {{ slotProps.option.start_date }}
                </div>
                <ArrowBigRight
                  size="18"
                  class="replacement-car-borrow-icon"
                />
                <div class="replacement-car-borrow-date">
                  {{ slotProps.option.end_date }}
                </div>
              </div>
            </div>
          </template>
          <template #empty>
            <div class="replacement-car-empty">
              Aucun véhicule ne correspond à votre recherche
            </div>
          </template>
        </AutoComplete>
        <label for="car_replacement">Véhicule de remplacement</label>
      </FloatLabel>
      <div class="replacement-car-dates">
        <FloatLabel variant="on">
          <DatePicker
            id="replacement-start-date"
            v-model="replacement.start_date"
            style="width: 14.5rem;"
            date-format="dd/mm/yy"
            :min-date="minDate"
          />
          <label for="replacement-start-date">Début du prêt</label>
        </FloatLabel>
        <FloatLabel variant="on">
          <DatePicker
            id="replacement-end-date"
            v-model="replacement.end_date"
            style="width: 14.5rem;"
            date-format="dd/mm/yy"
          />
          <label for="replacement-end-date">Fin du prêt</label>
        </FloatLabel>
      </div>
      <button
        v-if="replacement.id"
        class="checkbox"
        @click="replacement.finished = !replacement.finished, $event.stopPropagation(), $event.preventDefault()"
      >
        <Checkbox
          v-model="replacement.finished"
          input-id="finished"
          :binary="true"
          class="checkbox-replacement"
        />
        <label
          for="finished"
          style="cursor: pointer; color: var(--color-text-secondary);"
        >Véchiule rendu</label>
      </button>
      <div
        v-if="conflictReplacements.length > 0"
        class="conflict-replacements"
      >
        <div class="conflict-message">
          <TriangleAlert size="18" />
          Le véhicule de remplacement est emprunté par {{ conflictReplacements.length === 1 ? "un" : conflictReplacements }} autre{{ conflictReplacements.length === 1 ? '':'s' }} client{{ conflictReplacements.length === 1 ? '':'s' }} aux mêmes dates
        </div>
        <div
          v-for="conflictReplacement in conflictReplacements"
          :key="conflictReplacement.id"
          class="conflict-replacements-list"
        >
          <div class="conflict-replacement">
            <ReplacementPartial
              :replacement="conflictReplacement"
              with-owner
            />
          </div>
        </div>
      </div>
      <div
        v-if="conflictMaintenanceTimes.length > 0"
        class="conflict-replacements"
      >
        <div class="conflict-message">
          <TriangleAlert size="18" />
          Le véhicule de remplacement est en maintenance aux mêmes dates
        </div>
      </div>
      <div class="replacement-car-form-validate">
        <button
          class="replacement-car-form-button"
          :disabled="!replacement.start_date || !replacement.end_date || !replacement.replacement_car || conflictReplacements.length > 0 || conflictMaintenanceTimes.length > 0"
          @click="handleValidate"
        >
          Valider
        </button>
      </div>
    </div>
  </Dialog>
</template>

<style lang="scss" scoped>
@import '../../../../../../ui/static_src/ui/base/mixins';

.replacement-header {
  @include subtitle;

  & span {
    color: var(--color-text-secondary);
  }
}

.replacement-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: .4rem;
}

.replacement-car-selecter {
  @include body;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.replacement-car-insurance-groups {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: .2rem;
}

.replacement-car-borrow-dates {
  display: flex;
  flex-direction: row;
  gap: .4rem;
  align-items: center;
  justify-content: center;
  color: var(--color-text-warning);
}

.replacement-car-borrow-icon {
  color: var(--color-text-warning);
  fill: var(--color-text-warning);
}

.replacement-car-header {
  @include subtitle;
  display: flex;
  width: calc(100% - 1.5rem);
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: .4px solid var(--color-border-secondary);
  margin-left: .75rem;
}

.replacement-car-valid {
  display: flex;
  align-items: center;
  justify-content: center;
}

.replacement-car-valid-icon {
  color: var(--color-text-success-light)
}

.replacement-car-invalid-icon {
  color: var(--color-text-warning)
}

.replacement-car-empty {
  @include body;
}

.replacement-car-dates {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 30rem;
}

.replacement-car-form-validate {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1.4rem;
}

.replacement-car-form-button {
  @include body;
  color: var(--color-text-quaternary);
  background-color: var(--color-button-bg-submit-full);
  padding: .4rem;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: var(--color-button-bg-submit-full-hover);
  }
}

.checkbox {
  display: flex;
  align-items: center;
  gap: .6rem;
  width: fit-content;
  padding-inline: 0;
  padding-block: 0;

  &:hover {
    background-color: var(--content-bg--color-lighter);
  }
}

.checkbox-replacement {
  width: .8rem;
  height: .8rem;
}

@keyframes fadeSlideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.conflict-replacements {
  display: flex;
  flex-direction: column;
  gap: .4rem;
  animation: fadeSlideIn 0.5s ease-out;
}

.conflict-message {
  @include body;
  color: var(--color-text-warning);
  display: flex;
  align-items: center;
  gap: .4rem;
}

.conflict-replacement {
  display: flex;
  flex-direction: column;
  border: .1px solid var(--color-text-quaternary-secondary);
  padding: .4rem;
  border-radius: var(--radius);
}
</style>
