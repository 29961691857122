<script setup>
import { Check } from "lucide-vue-next"
import Timeline from "primevue/timeline"
import { computed } from "vue"

import { getFullDate } from "../../utils/date.js"

const props = defineProps({
  replacement: {
    type: Object,
    required: true,
  },
  withOwner: {
    type: Boolean,
    default: false,
  },
})

const isBeforeToday = (strDate) => {
  return new Date(strDate).getTime() < new Date().getTime()
}

const carName = computed(() => {
  let replacementCarName = ""
  if (props.replacement.replacement_car.brand) {
    replacementCarName = `${props.replacement.replacement_car.registration} - ${props.replacement.replacement_car.brand} - ${props.replacement.replacement_car.model}`
  } else {
    replacementCarName = `${props.replacement.replacement_car.registration} - ${props.replacement.replacement_car.model}`
  }

  if (props.withOwner) {
    replacementCarName = `${replacementCarName}, emprunté par ${props.replacement.car_incharge.owner_last_name} | ${props.replacement.car_incharge.model}`
  }

  return replacementCarName
})

const timeline = computed(() => {
  return [
    { date: props.replacement.start_date, label: "start" },
    { date: props.replacement.end_date, label: "end" },
  ]
})

const isReplacementFinished = computed(() => {
  return props.replacement.finished && new Date(props.replacement.end_date).getTime() < new Date().getTime()
})

const insuranceGroupColor = computed(() => {
  if (!props.replacement || props.replacement.replacement_car.insurance_groups.length === 0) return ""
  return props.replacement.replacement_car.insurance_groups[0].color
})
</script>

<template>
  <div class="replacement-car-name">
    <div :class="['car__card-state', `-${insuranceGroupColor}`]" />
    {{ carName }}
  </div>
  <Timeline
    :value="timeline"
    layout="horizontal"
    class="replacement-car-timeline"
  >
    <template #content="slotProps">
      <div class="replacement-car-date">
        {{ getFullDate(new Date(slotProps.item.date)) }}
      </div>
    </template>
    <template #opposite="slotProps">
      <div
        v-if="slotProps.item.label === 'end'"
        :class="['label-text', isReplacementFinished ? '-finished' : '-waiting' ]"
      >
        {{ isReplacementFinished ? 'Véhicule rendu' : 'Véhicule non rendu' }}
      </div>
      <div
        v-else
        :class="['label-text', '-start', isBeforeToday(slotProps.item.date) ? '-finished' : '-waiting']"
      >
        {{ isBeforeToday(slotProps.item.date) ? 'Véhicule emprunté' : 'Véhicule prévu' }}
      </div>
    </template>
    <template #marker="slotProps">
      <div
        v-if="isBeforeToday(slotProps.item.date) && slotProps.item.label === 'start'"
        class="replacement-car-marker-date"
      >
        <Check
          size="14"
          class="replacement-car-icon"
        />
      </div>
      <div
        v-else-if="isReplacementFinished && slotProps.item.label === 'end'"
        class="replacement-car-marker-date -end"
      >
        <Check
          size="14"
          class="replacement-car-icon"
        />
      </div>
      <div
        v-else
        class="replacement-car-marker"
      />
    </template>
  </Timeline>
</template>

<style lang="scss" scoped>
@import '../../../../../ui/static_src/ui/base/mixins';

.replacement-car-name,
.replacement-car-date {
  @include body;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .4rem;
}

.label-text.-finished {
  color: var(--color-text-success);
}

.label-text.-waiting {
  color: var(--color-text-secondary);
}

.label-text.-start.-finished {
  color: var(--color-button-bg-submit);
}

.replacement-car-marker {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  align-self: baseline;
  border-width: 2px;
  border-style: solid;
  border-color: var(--color-text-secondary);
  border-radius: 999px;
  width: 18px;
  height: 18px;
  align-self: center;
}

.replacement-car-icon {
  color: var(--color-text-quaternary);
}

.replacement-car-marker-date {
  background-color: var(--color-button-bg-submit);
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;

  &.-end {
    background-color: var(--color-text-success);
  }
}
</style>
