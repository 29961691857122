<script setup>
import { computed } from "vue"

import { STATES_LIST } from "../../utils/states.js"

const props = defineProps({
  object: {
    type: Object,
    required: true,
  },
})

const state = computed(() => props.object.type === "car" ? props.object.car.state : props.object.work.car.state)
const car = computed(() => props.object.type === "car" ? props.object.car : props.object.work.car)
const baseObject = computed(() => props.object.type === "car" ? props.object.car : props.object.work)
</script>

<template>
  {{ STATES_LIST.find(s => s.value === state).name }}
  <div
    v-if="state === 'repairing'"
    class="car-repairing-states"
  >
    <div
      :class="['car-repairing-state', baseObject.sheet_metal_work_hours === 0 ? '-disable' : '']"
    >
      <div :class="`car__card-state -repairing -${car.sheet_metal_state}`" />
      Tôlerie
    </div>
    <div
      :class="['car-repairing-state', baseObject.painting_hours === 0 ? '-disable' : '']"
    >
      <div :class="`car__card-state -repairing -${car.painting_state}`" />
      Peinture
    </div>
    <div
      :class="['car-repairing-state', baseObject.mechanic_hours === 0 ? '-disable' : '']"
    >
      <div :class="`car__card-state -repairing -${car.mechanic_state}`" />
      Mécanique
    </div>
  </div>
</template>

<style lang="scss" scoped>
.car-repairing-states {
  display: flex;
  flex-direction: column;
  gap: .4rem;
  margin-top: .4rem;
}

.car-repairing-state {
  display: flex;
  align-items: center;
  gap: .4rem;

  &.-disable {
    opacity: .3;
  }
}
</style>
