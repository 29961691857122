<script setup>
import { Clipboard, Clock, Folder, UserRound } from "lucide-vue-next"
import Checkbox from "primevue/checkbox"
import DatePicker from "primevue/datepicker"
import FloatLabel from "primevue/floatlabel"
import InputNumber from "primevue/inputnumber"
import InputText from "primevue/inputtext"
import Select from "primevue/select"
import Textarea from "primevue/textarea"
import { computed } from "vue"

import RegistrationCar from "../../../../../documents/static_src/documents/components/RegistrationCar.vue"

import { svgLink } from "../../../../../companies/static_src/companies/utils/svg.js"
import { getStateDate } from "../../utils/car.js"
import { STATES_LIST } from "../../utils/states.js"

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
  handleValidate: {
    type: Function,
    required: true,
  },
  loading: {
    type: Boolean,
    required: true,
  },
})

const currentStateName = computed(() => {
  return STATES_LIST.find(s => s.value === props.modelValue.state).name
})

const emit = defineEmits(["update:modelValue", "registration-car-data-extracted"])

const updateModel = (key, value, event = null) => {
  if (event) {
    event.preventDefault()
    event.stopPropagation()
  }

  emit("update:modelValue", {
    ...props.modelValue,
    [key]: value,
  })
}

const handleRegistrationCarExtractedData = (data) => {
  emit("registration-car-data-extracted", data)
}
</script>

<template>
  <div class="car-incharge-form">
    <RegistrationCar
      :car="modelValue"
      @data-extracted="handleRegistrationCarExtractedData"
    />
    <div class="car-incharge-form-block">
      <FloatLabel variant="on">
        <InputText
          id="registration"
          :model-value="modelValue.registration"
          style="width: 100%;"
          @update:model-value="(value) => updateModel('registration', value)"
        />
        <label for="registration">Plaque d'immatriculation</label>
      </FloatLabel>
      <div class="car-incharge-form-block -row -no-padding">
        <FloatLabel variant="on">
          <InputText
            id="brand"
            :model-value="modelValue.brand"
            style="min-width: 12.5rem;"
            @update:model-value="(value) => updateModel('brand', value)"
          />
          <label for="brand">Marque</label>
        </FloatLabel>
        <FloatLabel variant="on">
          <InputText
            id="model"
            :model-value="modelValue.model"
            style="min-width: 12.5rem;"
            required
            @update:model-value="(value) => updateModel('model', value)"
          />
          <label for="model">Modèle</label>
        </FloatLabel>
      </div>
      <FloatLabel variant="on">
        <DatePicker
          id="first_registration_date"
          :model-value="modelValue.first_registration_date"
          style="width: 100%;"
          date-format="dd/mm/yy"
          @update:model-value="(value) => updateModel('first_registration_date', value)"
        />
        <label for="first_registration_date">Date de 1ère immatriculation</label>
      </FloatLabel>
      <FloatLabel variant="on">
        <InputText
          id="foreground_note"
          :model-value="modelValue.foreground_note"
          style="width: 100%;"
          @update:model-value="(value) => updateModel('foreground_note', value)"
        />
        <label for="foreground_note">Note</label>
      </FloatLabel>
    </div>
    <div class="car-incharge-form-block">
      <div class="car-form-subtitle">
        <UserRound size="24" />
        Propriétaire
      </div>
      <div class="car-incharge-form-block -row -no-padding">
        <FloatLabel variant="on">
          <InputText
            id="owner_first_name"
            :model-value="modelValue.owner_first_name"
            style="min-width: 12.5rem;"
            @update:model-value="(value) => updateModel('owner_first_name', value)"
          />
          <label for="owner_first_name">Prénom</label>
        </FloatLabel>
        <FloatLabel variant="on">
          <InputText
            id="owner_last_name"
            :model-value="modelValue.owner_last_name"
            style="min-width: 12.5rem;"
            @update:model-value="(value) => updateModel('owner_last_name', value)"
          />
          <label for="owner_last_name">Nom</label>
        </FloatLabel>
      </div>
      <div class="car-incharge-form-block -row -no-padding">
        <FloatLabel variant="on">
          <InputText
            id="owner_phone_number"
            :model-value="modelValue.owner_phone_number"
            style="min-width: 12.5rem;"
            @update:model-value="(value) => updateModel('owner_phone_number', value)"
          />
          <label for="owner_phone_number">Numéro de téléphone 1</label>
        </FloatLabel>
        <FloatLabel variant="on">
          <InputText
            id="owner_phone_number_2"
            :model-value="modelValue.owner_phone_number_2"
            style="min-width: 12.5rem;"
            @update:model-value="(value) => updateModel('owner_phone_number_2', value)"
          />
          <label for="owner_phone_number_2">Numéro de téléphone 2</label>
        </FloatLabel>
      </div>
      <FloatLabel variant="on">
        <InputText
          id="owner_email"
          :model-value="modelValue.owner_email"
          style="width: 100%;"
          @update:model-value="(value) => updateModel('owner_email', value)"
        />
        <label for="owner_phone_number_2">Email</label>
      </FloatLabel>
      <FloatLabel variant="on">
        <InputText
          id="owner_address"
          :model-value="modelValue.owner_address"
          style="width: 100%;"
          @update:model-value="(value) => updateModel('owner_address', value)"
        />
        <label for="owner_address">Adresse</label>
      </FloatLabel>
    </div>
    <div class="car-incharge-form-block">
      <div class="car-form-subtitle">
        <Folder size="24" />
        Dossier
      </div>
      <button
        class="car-form-checkbox"
        @click="updateModel('complete_file', !modelValue.complete_file, $event)"
      >
        <Checkbox
          :model-value="modelValue.complete_file"
          input-id="complete_file"
          :binary="true"
          class="checkbox-car-incharge"
        />
        <label
          for="complete_file"
          style="cursor: pointer; color: var(--color-text-secondary);"
        >Dossier complet</label>
      </button>
      <button
        class="car-form-checkbox"
        @click="updateModel('repair_order', !modelValue.repair_order, $event)"
      >
        <Checkbox
          :model-value="modelValue.repair_order"
          input-id="repair_order"
          :binary="true"
          class="checkbox-car-incharge"
        />
        <label
          for="repair_order"
          style="cursor: pointer; color: var(--color-text-secondary);"
        >Ordre de réparation prêt</label>
      </button>
      <button
        class="car-form-checkbox"
        @click="updateModel('valid_support', !modelValue.valid_support, $event)"
      >
        <Checkbox
          :model-value="modelValue.valid_support"
          input-id="valid_support"
          :binary="true"
          class="checkbox-car-incharge"
        />
        <label
          for="valid_support"
          style="cursor: pointer; color: var(--color-text-secondary);"
        >Prise en charge valide</label>
      </button>
      <button
        class="car-form-checkbox"
        @click="updateModel('from_tow_truck', !modelValue.from_tow_truck, $event)"
      >
        <Checkbox
          :model-value="modelValue.from_tow_truck"
          input-id="from_tow_truck"
          :binary="true"
          class="checkbox-car-incharge"
        />
        <label
          for="from_tow_truck"
          style="cursor: pointer; color: var(--color-text-secondary);"
        >Arrivé par dépannage</label>
      </button>
      <button
        class="car-form-checkbox"
        @click="updateModel('is_seriously_damaged', !modelValue.is_seriously_damaged, $event)"
      >
        <Checkbox
          :model-value="modelValue.is_seriously_damaged"
          input-id="is_seriously_damaged"
          :binary="true"
          class="checkbox-car-incharge"
        />
        <label
          for="is_seriously_damaged"
          style="cursor: pointer; color: var(--color-text-secondary);"
        >Véhicule gravement endommagé</label>
      </button>
      <slot />
    </div>
    <div class="car-incharge-form-block">
      <div class="car-form-subtitle">
        <svg class="car-form-subtitle-icon">
          <use :href="svgLink('cars', 'car-replacement')" />
        </svg>
        Remplacement
      </div>
      <button
        class="car-form-checkbox"
        @click="updateModel('need_replacement', !modelValue.need_replacement, $event)"
      >
        <Checkbox
          :model-value="modelValue.need_replacement"
          input-id="need_replacement"
          :binary="true"
          class="checkbox-car-incharge"
        />
        <label
          for="need_replacement"
          style="cursor: pointer; color: var(--color-text-secondary);"
        >Le propriétaire souhaite un véhicule de remplacement</label>
      </button>
    </div>
    <div class="car-incharge-form-block">
      <div class="car-form-subtitle">
        <Clipboard size="24" />
        Travail
      </div>
      <div class="car-incharge-form-block -row -no-padding">
        <FloatLabel variant="on">
          <Select
            id="state"
            :model-value="currentStateName"
            :options="STATES_LIST.map(s => s.name)"
            style="width: 12rem;"
            @value-change="(value) => updateModel('state', STATES_LIST.find(s => s.name === value).value)"
          >
            <template #value>
              <div class="state-value">
                <div :class="`car__card-state -${modelValue.state}`" />
                {{ currentStateName }}
              </div>
            </template>
            <template #option="{ option }">
              <div class="state-value">
                <div :class="`car__card-state -${STATES_LIST.find(s => s.name === option).value}`" />
                {{ option }}
              </div>
            </template>
          </Select>
          <label for="state">État</label>
        </FloatLabel>
        <FloatLabel
          v-if="modelValue.state === 'breakage'"
          variant="on"
        >
          <DatePicker
            id="breakage_date"
            :model-value="modelValue.breakage_date"
            style="min-width: 13rem;"
            date-format="dd/mm/yy"
            @value-change="(value) => updateModel('breakage_date', value)"
          />
          <label for="breakage_date">Date de départ à la casse</label>
        </FloatLabel>
        <FloatLabel
          v-else-if="modelValue.state === 'expertise'"
          variant="on"
        >
          <DatePicker
            id="expertise_date"
            :model-value="modelValue.expertise_date"
            style="min-width: 13rem;"
            date-format="dd/mm/yy"
            @value-change="(value) => updateModel('expertise_date', value)"
          />
          <label for="expertise_date">Date de l'expertise</label>
        </FloatLabel>
        <FloatLabel
          v-else-if="modelValue.state === 'waiting'"
          variant="on"
        >
          <DatePicker
            id="waiting_date"
            :model-value="modelValue.waiting_date"
            style="min-width: 13rem;"
            date-format="dd/mm/yy"
            @value-change="(value) => updateModel('waiting_date', value)"
          />
          <label for="waiting_date">En attente depuis</label>
        </FloatLabel>
        <FloatLabel
          v-else
          variant="on"
        >
          <DatePicker
            id="start_work"
            :model-value="modelValue.start_work"
            style="min-width: 13rem;"
            date-format="dd/mm/yy"
            @value-change="(value) => updateModel('start_work', value)"
          />
          <label for="start_work">Date de début des réparations</label>
        </FloatLabel>
      </div>
      <button
        v-if="modelValue.state === 'breakage'"
        class="car-form-checkbox"
        @click="updateModel('wreck_delivered', !modelValue.wreck_delivered, $event)"
      >
        <Checkbox
          :model-value="modelValue.wreck_delivered"
          input-id="wreck_delivered"
          :binary="true"
          class="checkbox-car-incharge"
        />
        <label
          for="wreck_delivered"
          style="cursor: pointer; color: var(--color-text-secondary);"
        >Épave livrée</label>
      </button>
      <FloatLabel
        v-if="['todo', 'coming', 'repairing', 'reassembly', 'finished', 'delivered'].includes(modelValue.state)"
        variant="on"
      >
        <DatePicker
          id="due_date"
          :model-value="modelValue.due_date"
          style="width: 100%;"
          date-format="dd/mm/yy"
          :min-date="new Date()"
          @value-change="(value) => updateModel('due_date', value)"
        />
        <label for="due_date">Date souhaitée de récupération du véhicule</label>
      </FloatLabel>
      <div class="car-incharge-form-block">
        <div class="car-form-subtitle">
          <Clock size="24" />
          Charge horaire
        </div>
        <div class="car-incharge-form-block -row -no-padding">
          <FloatLabel variant="on">
            <InputNumber
              id="sheet_metal_work_hours"
              :model-value="modelValue.sheet_metal_work_hours"
              :min-fraction-digits="1"
              style="width: 8rem;"
              type="number"
              locale="en-US"
              @update:model-value="(value) => updateModel('sheet_metal_work_hours', value)"
            />
            <label for="sheet_metal_work_hours">Tôlerie</label>
          </FloatLabel>
          <FloatLabel variant="on">
            <InputNumber
              id="painting_hours"
              :model-value="modelValue.painting_hours"
              :min-fraction-digits="1"
              style="width: 8rem;"
              type="number"
              locale="en-US"
              @update:model-value="(value) => updateModel('painting_hours', value)"
            />
            <label for="painting_hours">Peinture</label>
          </FloatLabel>
          <FloatLabel variant="on">
            <InputNumber
              id="mechanic_hours"
              :model-value="modelValue.mechanic_hours"
              :min-fraction-digits="1"
              style="width: 8rem;"
              type="number"
              locale="en-US"
              @update:model-value="(value) => updateModel('mechanic_hours', value)"
            />
            <label for="mechanic_hours">Mécanique</label>
          </FloatLabel>
        </div>
      </div>
    </div>
    <Textarea
      id="background_note"
      :model-value="modelValue.background_note"
      rows="5"
      cols="30"
      style="resize: none; width: 100%;"
      placeholder="Écrire un commentaire"
      @update:model-value="(value) => updateModel('background_note', value)"
    />
    <div class="car-incharge-form-validate">
      <i
        v-if="loading"
        class="pi pi-spin pi-cog loading-icon"
      />
      <button
        v-else
        class="car-incharge-form-button"
        :disabled="!getStateDate(modelValue) || !modelValue.model || !modelValue.owner_last_name"
        @click="handleValidate"
      >
        Valider
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../../../ui/static_src/ui/base/mixins';

.car-incharge-form-block {
  display: flex;
  flex-direction: column;
  padding: .4rem 0rem;
  gap: 1rem;
  width: 100%;

  &.-row {
    flex-direction: row;
    justify-content: space-between;
  }

  &.-no-padding {
    padding: 0;
  }
}

.car-form-subtitle {
  @include subtitle;
  display: flex;
  align-items: center;
  gap: .4rem;
  margin-top: .4rem;
}

.car-form-subtitle-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.car-form-checkbox {
  display: flex;
  align-items: center;
  gap: .6rem;
  width: fit-content;
  padding-inline: 0;
  padding-block: 0;

  &:hover {
    background-color: var(--content-bg--color-lighter);
  }
}

.state-value {
  display: flex;
  align-items: center;
  gap: .8rem;
}

.car-incharge-form-validate {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1.4rem;
}

.car-incharge-form-button {
  @include body;
  color: var(--color-text-quaternary);
  background-color: var(--color-button-bg-submit-full);
  padding: .4rem;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: var(--color-button-bg-submit-full-hover);
  }

  &.-long {
    width: 14rem;
    align-self: center;
  }
}

.loading-icon {
  color: var(--color-text-primary);
  font-size: 1.2rem;
  margin-right: 3rem;
}
</style>
