<script setup>
import { computed } from "vue"
import CalendarChargeChart from "./CalendarChargeChart.vue"
import { computeWork } from "../../utils/cars.js"

const props = defineProps({
  cars: {
    type: Object,
    required: true,
  },
  delayedWorks: {
    type: Object,
    required: true,
  },
  weekCapacity: {
    type: Object,
    required: true,
  },
  delayAnimation: {
    type: Boolean,
    required: false,
  },
})

const weekWork = computed(() => computeWork(props.cars, props.delayedWorks))
</script>

<template>
  <div class="calendar-week-chart">
    <CalendarChargeChart
      :work="weekWork"
      :capacity="weekCapacity"
      font-size-value="high"
      :delay-animation="props.delayAnimation"
    />
  </div>
</template>

<style lang="scss" scoped>
.calendar-week-chart {
  display: flex;
  width: 100%;
  height: 7.5rem;
}
</style>
