export const carObject = (object) => object.type === "car" ? object.car : object.work.car

export const getStateDate = (car) => {
  switch (car.state) {
    case "breakage":
      return car.breakage_date
    case "expertise":
      return car.expertise_date
    case "waiting":
      return car.waiting_date
    default:
      return car.start_work
  }
}
