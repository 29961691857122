<script setup>
import ReplacementPartial from "../_partials/Replacement.vue"

const props = defineProps({
  replacement: {
    type: [Object, null],
    required: true,
  },
})
</script>

<template>
  <div
    v-if="!props.replacement"
    class="replacement-car-tooltpip"
  >
    <div>Prêter un véhicule de remplacement</div>
  </div>
  <div
    v-else
    class="replacement-car-tooltpip"
  >
    <ReplacementPartial :replacement="props.replacement" />
  </div>
</template>

<style lang="scss" scoped>
@import '../../../../../ui/static_src//ui//base/mixins';

.replacement-car-tooltpip {
  display: flex;
  flex-direction: column;
  gap: .4rem;
}

.replacement-car-name,
.replacement-car-date {
  @include body;
  color: var(--color-text-quaternary);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .4rem;
}

.label-text.-finished {
  color: var(--color-text-success);
}

.label-text.-waiting {
  color: var(--color-text-secondary);
}

.label-text.-start.-finished {
  color: var(--color-button-bg-submit);
}

.replacement-car-marker {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  align-self: baseline;
  border-width: 2px;
  border-style: solid;
  border-color: var(--color-text-secondary);
  border-radius: 999px;
  width: 18px;
  height: 18px;
  align-self: center;
}

.replacement-car-icon {
  color: var(--color-text-quaternary);
}

.replacement-car-marker-date {
  background-color: var(--color-button-bg-submit);
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;

  &.-end {
    background-color: var(--color-text-success);
  }
}
</style>
