import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["DocumentInput"], {
      file: $setup.file,
      "error-raised": $setup.errorRaised,
      "accepted-formats": ".pdf,.jpeg,application/pdf,image/jpeg",
      placeholder: "Carte grise en format pdf ou jpeg",
      label: "Carte grise",
      onFileSelected: $setup.handleFileSelected,
      onDeleteFile: $setup.deleteFile,
      onOpenDocumentPreview: _cache[0] || (_cache[0] = $event => ($setup.openPreviewModal = true))
    }, null, 8 /* PROPS */, ["file", "error-raised"]),
    _createVNode($setup["Dialog"], {
      visible: $setup.openPreviewModal,
      "onUpdate:visible": _cache[1] || (_cache[1] = $event => (($setup.openPreviewModal) = $event)),
      class: "document-preview"
    }, {
      default: _withCtx(() => [
        ($setup.file.content.type.includes('image'))
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: $setup.fileAsBase64Image,
              height: "600",
              width: "400"
            }, null, 8 /* PROPS */, _hoisted_1))
          : (_openBlock(), _createElementBlock("iframe", {
              key: 1,
              src: $setup.fileAsBase64Image,
              height: "600",
              width: "400"
            }, null, 8 /* PROPS */, _hoisted_2))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["visible"])
  ], 64 /* STABLE_FRAGMENT */))
}