import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "calendar-week-day-name" }
const _hoisted_2 = {
  key: 0,
  class: "calendar-week-day-work"
}
const _hoisted_3 = {
  key: 1,
  class: "calendar-week-day-cars"
}
const _hoisted_4 = {
  key: 2,
  class: "calendar-week-holidays-message"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "dayRef",
    class: _normalizeClass(['calendar-week-day', {'-limit': $setup.isOutOfDueDate }, { '-focusable': $setup.carStore.draggingCar || $setup.carStore.draggingWork }]),
    onClick: $setup.selectDay
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString($setup.getDateDayName($setup.props.day)), 1 /* TEXT */),
    (!$props.publicHolidayName)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode($setup["CalendarChargeChart"], {
            work: $setup.dayWork,
            capacity: $setup.capacity,
            "font-size-value": "small",
            "delay-animation": $setup.props.delayAnimation
          }, null, 8 /* PROPS */, ["work", "capacity", "delay-animation"])
        ]))
      : _createCommentVNode("v-if", true),
    (!$props.publicHolidayName)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.currentSlots.filter(slot => slot.display), (slot) => {
            return (_openBlock(), _createBlock($setup["CalendarCar"], {
              key: slot.type === 'car' ? slot.car.id : slot.work.car.id,
              object: slot
            }, null, 8 /* PROPS */, ["object"]))
          }), 128 /* KEYED_FRAGMENT */)),
          _createElementVNode("button", {
            class: "add-car-button",
            onClick: $setup.openCarInChargeForm
          }, [
            _createVNode($setup["PlusIcon"], {
              size: "20",
              class: "add-car-button-icon"
            })
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($props.publicHolidayName), 1 /* TEXT */))
  ], 2 /* CLASS */))
}