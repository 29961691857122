import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "calendar-week-day-arrival-date-selecter" }
const _hoisted_2 = { class: "submit-container" }
const _hoisted_3 = ["disabled"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Dialog"], {
    visible: $setup.localOpenModal,
    "onUpdate:visible": _cache[2] || (_cache[2] = $event => (($setup.localOpenModal) = $event)),
    header: "Depuis quelle date le véhicule est-il en attente ?",
    modal: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode($setup["FloatLabel"], { variant: "on" }, {
          default: _withCtx(() => [
            _createVNode($setup["DatePicker"], {
              id: "waiting_date",
              modelValue: $setup.arrivalDate,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.arrivalDate) = $event)),
              style: {"min-width":"13rem"},
              "date-format": "dd/mm/yy",
              required: ""
            }, null, 8 /* PROPS */, ["modelValue"]),
            _cache[3] || (_cache[3] = _createElementVNode("label", { for: "start_work" }, "En attente depuis", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          class: "submit",
          disabled: !$setup.arrivalDate,
          onClick: _cache[1] || (_cache[1] = $event => ($setup.props.handleValidate($setup.arrivalDate)))
        }, " Enregistrer ", 8 /* PROPS */, _hoisted_3)
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible"]))
}