<script setup>
import { h } from "vue"
import { asyncComputed } from "@vueuse/core/index.cjs"
import { renderToString } from "vue/server-renderer"

import CarFollowUp from "../../../../../cars/static_src/cars/components/tooltips/CarFollowUp.vue"

const props = defineProps({
  car: {
    type: Object,
    required: true,
  },
})

const carFollowupTooltipOptions = asyncComputed(async() => {
  const htmlContent = await renderToString(h(CarFollowUp, { car: props.car }))
  return { escape: false, value: htmlContent }
})
</script>

<template>
  <div
    v-if="props.car.complete_file || props.car.repair_order || props.car.valid_support"
    v-tooltip.left="carFollowupTooltipOptions"
    :class="['calendar-car-followup', car.is_deleted ? '-deleted' : '']"
  >
    <div :class="['car-followup-point', '-complete-file', props.car.complete_file ? '' : '-hidden']" />
    <div :class="['car-followup-point', '-repair-order', props.car.repair_order ? '' : '-hidden']" />
    <div :class="['car-followup-point', '-valid-support', props.car.valid_support ? '' : '-hidden']" />
  </div>
  <div
    v-else
    class="calendar-car-followup"
  />
</template>

<style lang="scss" scoped>
.calendar-car-followup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0 0 .4rem;
  min-width: .4rem;
  gap: .2rem;

  &.-deleted {
    opacity: .4;
  }
}

.car-followup-point {
  width: .2rem;
  height: .2rem;
  border-radius: 50%;

  &.-complete-file {
    background-color: var(--car-followup-complete-file);
  }

  &.-repair-order {
    background-color: var(--car-followup-repair-order);
  }

  &.-valid-support {
    background-color: var(--car-followup-valid-support);
  }

  &.-hidden {
    background-color: transparent;
  }
}
</style>
