import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["href"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 1,
  class: "state-selecter",
  disabled: ""
}
const _hoisted_5 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_tooltip = _resolveDirective("tooltip")

  return _withDirectives((_openBlock(), _createElementBlock("button", {
    id: `car-${$setup.carObject($setup.props.object).id}`,
    class: _normalizeClass(['calendar-week-day-car-state-container', $setup.carObject($setup.props.object).is_deleted ? '-deleted' : '']),
    onClick: $setup.openStates
  }, [
    ($setup.calendarStore.isHomepage && $setup.props.object.type === 'car')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(`car__card-state -${$setup.carObject($setup.props.object).state}`)
        }, null, 2 /* CLASS */))
      : ($setup.calendarStore.isHomepage && $setup.props.object.type === 'work')
        ? (_openBlock(), _createElementBlock("svg", {
            key: 1,
            class: _normalizeClass(`car__delay-work-icon -${$setup.carObject($setup.props.object).state}`)
          }, [
            _createElementVNode("use", {
              href: $setup.svgLink('cars', 'delay')
            }, null, 8 /* PROPS */, _hoisted_2)
          ], 2 /* CLASS */))
        : (_openBlock(), _createBlock($setup["SplitButton"], {
            key: 2,
            class: "calendar-week-day-car-state",
            model: $setup.getStateList(),
            pt: { pcMenu: { root: { class: 'calendar-week-day-car-state-menu' } } }
          }, {
            item: _withCtx((slotProps) => [
              (slotProps.item.value !== $setup.carObject($setup.props.object).state || $setup.carObject($setup.props.object).state === 'repairing')
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "state-selecter",
                    onClick: $event => ($setup.onSelectState(slotProps.item.value))
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(`car__card-state -${slotProps.item.value}`)
                    }, null, 2 /* CLASS */),
                    _createTextVNode(" " + _toDisplayString(slotProps.item.label), 1 /* TEXT */)
                  ], 8 /* PROPS */, _hoisted_3))
                : (_openBlock(), _createElementBlock("button", _hoisted_4, [
                    _createElementVNode("div", {
                      class: _normalizeClass(`car__card-state -${slotProps.item.value}`)
                    }, null, 2 /* CLASS */),
                    _createTextVNode(" " + _toDisplayString(slotProps.item.label), 1 /* TEXT */)
                  ]))
            ]),
            default: _withCtx(() => [
              ($setup.props.object.type === 'car')
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(`car__card-state -${$setup.carObject($setup.props.object).state}`)
                  }, null, 2 /* CLASS */))
                : (_openBlock(), _createElementBlock("svg", {
                    key: 1,
                    class: _normalizeClass(`car__delay-work-icon -${$setup.carObject($setup.props.object).state}`)
                  }, [
                    _createElementVNode("use", {
                      href: $setup.svgLink('cars', 'delay')
                    }, null, 8 /* PROPS */, _hoisted_5)
                  ], 2 /* CLASS */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["model"])),
    _createVNode($setup["RepairingStatesDialog"], {
      "open-modal": $setup.repairingStatesModalOpen,
      object: $setup.props.object,
      car: $setup.carObject($setup.props.object),
      "handle-validate": $setup.onSelectRepairingState
    }, null, 8 /* PROPS */, ["open-modal", "object", "car"]),
    _createVNode($setup["WaitingDateDialog"], {
      modelValue: $setup.waitingDateModalOpen,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.waitingDateModalOpen) = $event)),
      object: $setup.props.object,
      car: $setup.carObject($setup.props.object),
      "handle-validate": $setup.onSelectWaitingDate
    }, null, 8 /* PROPS */, ["modelValue", "object", "car"])
  ], 10 /* CLASS, PROPS */, _hoisted_1)), [
    [
      _directive_tooltip,
      $setup.stateTooltipOptions,
      void 0,
      { left: true }
    ]
  ])
}