import { createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "calendar-week-day" }
const _hoisted_2 = { class: "calendar-week-day-work" }
const _hoisted_3 = { class: "calendar-week-day-cars" }
const _hoisted_4 = { class: "add-car-button" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "calendar-week-day-name" }, " Jeudi 26/09 ", -1 /* HOISTED */)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["CalendarChargeChart"], {
        work: $setup.dayWork,
        capacity: $setup.capacity,
        "font-size-value": "small"
      }, null, 8 /* PROPS */, ["work"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.slots.filter(slot => slot.display), (slot) => {
        return (_openBlock(), _createBlock($setup["CalendarCar"], {
          key: slot.type === 'car' ? slot.car.id : slot.work.car.id,
          object: slot
        }, null, 8 /* PROPS */, ["object"]))
      }), 128 /* KEYED_FRAGMENT */)),
      _createElementVNode("button", _hoisted_4, [
        _createVNode($setup["PlusIcon"], {
          size: "20",
          class: "add-car-button-icon"
        })
      ])
    ])
  ]))
}