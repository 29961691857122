<script setup>
import { ref, h, onMounted, onUnmounted, watch, computed } from "vue"
import { StickyNote, Flame } from "lucide-vue-next"
import { renderToString } from "vue/server-renderer"
import { asyncComputed } from "@vueuse/core/index.cjs"

import CalendarCarFollowUp from "./CalendarCarFollowUp.vue"
import CarState from "./CarState.vue"
import CarDetails from "../../../../../cars/static_src/cars/components/tooltips/CarDetails.vue"
import CalendarCarOptions from "./CalendarCarOptions.vue"
import CalendarCarReplacement from "./CalendarCarReplacement.vue"

import { carObject, getStateDate, isOnlyMechanic } from "../../utils/cars.js"
import { svgLink } from "../../utils/svg.js"
import { getFullDate } from "../../utils/date.js"
import { useCarStore } from "../../stores/car.js"

const props = defineProps({
  object: {
    type: Object,
    required: true,
  },
  draggable: {
    type: Boolean,
    default: false,
  },
})

const carStore = useCarStore()
const containerRef = ref(null)
const carNameRef = ref(null)
const isExpanded = ref(false)
const rectContainer = ref({ left: 0, top: 0 })

const car = computed(() => carObject(props.object))
const carNameTooltipOptions = asyncComputed(async() => {
  const htmlContent = await renderToString(h(CarDetails, { object: props.object }))
  return { escape: false, value: htmlContent }
})

const toggleExpandedCar = () => {
  isExpanded.value = !isExpanded.value
}

const clickOutSide = (event) => {
  if (carNameRef.value && !carNameRef.value.contains(event.target)) {
    isExpanded.value = false
  }
}

const onDrag = (event) => {
  if (containerRef.value) {
    const x = event.clientX - rectContainer.value.left + 5
    const y = event.clientY - rectContainer.value.top + 5
    containerRef.value.style.left = `${x}px`
    containerRef.value.style.top = `${y}px`
  }
}

const isSearchResult = computed(() => {
  const carId = window.location.search.split("&search=")[1]
  return carId && parseInt(carId) === car.value.id
})

watch([carStore.draggingCarStartPosition, carStore.draggingWorkStartPosition], (newValue) => {
  if (containerRef.value && props.draggable) {
    containerRef.value.style.left = `${newValue.x + 5}px`
    containerRef.value.style.top = `${newValue.y + 5}px`
  }
})

onMounted(() => {
  document.addEventListener("click", clickOutSide)

  if (props.draggable) {
    document.addEventListener("mousemove", onDrag)
    const startPostion = carStore.draggingCar ? carStore.draggingCarStartPosition : carStore.draggingWorkStartPosition
    if (startPostion) {
      containerRef.value.style.left = `${startPostion.x + 5}px`
      containerRef.value.style.top = `${startPostion.y + 5}px`
    }
  }
})

onUnmounted(() => {
  document.removeEventListener("click", clickOutSide)

  if (props.draggable) {
    document.removeEventListener("mousemove", onDrag)
  }
})
</script>

<template>
  <div
    ref="containerRef"
    :class="[
      'calendar-week-day-car',
      isOnlyMechanic(car) ? '-mechanic' : '',
      car.due_date ? '-highlighted' : '',
      car.foreground_note ? '-with-note' : '',
      car.is_deleted ? '-deleted' : '',
      props.object.type === 'work' ? '-work' : '-car',
      isExpanded ? '-expanded' : '',
      props.draggable ? '-draggable' : '',
    ]"
  >
    <div class="calendar-week-day-car-container">
      <CarState :object="props.object" />
      <div
        ref="carNameRef"
        class="calendar-week-day-car-name"
        @click="toggleExpandedCar()"
      >
        <span
          :id="`car-${car.id}`"
          v-tooltip.top="carNameTooltipOptions"
          :class="['car-name', { '-result': isSearchResult }]"
        >{{ car.owner_last_name }} | {{ car.model }}</span>
      </div>
      <div class="calendar-week-day-car-slot">
        <span
          v-if="car.is_seriously_damaged"
          v-tooltip.top="'VGE'"
        >
          <Flame
            size="18"
            class="seriously-damaged-icon"
          />
        </span>
        <span
          v-else-if="car.from_tow_truck"
          v-tooltip.top="`Arrivé par dépannage le ${getFullDate(car.waiting_date ? new Date(car.waiting_date) : new Date(getStateDate(car)))}`"
        >
          <svg class="tow-truck-icon">
            <use :href="svgLink('cars', 'tow-truck')" />
          </svg>
        </span>
      </div>
      <div class="calendar-week-day-car-slot">
        <CalendarCarReplacement
          v-if="car.need_replacement"
          :object="props.object"
        />
      </div>
      <CalendarCarFollowUp :car="car" />
    </div>
    <div
      v-if="car.foreground_note"
      class="calendar-week-day-car-note-container"
    >
      <StickyNote size="18" />
      <span class="calendar-week-day-car-note">{{ car.foreground_note }}</span>
    </div>
    <transition name="fade">
      <CalendarCarOptions
        v-if="isExpanded"
        :object="props.object"
      />
    </transition>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../../../ui/static_src/ui/base/mixins';

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.calendar-week-day-car {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  border: 0.5px solid var(--color-border-tertiary);
  border-radius: var(--radius);
  background-color: var(--content-bg--color-light);
  padding-inline: 6px;
  height: 1.6rem;
  transition: height 0.2s ease-in-out;
  overflow: hidden;

  &.-draggable {
    position: absolute;
    width: calc((100% - 25rem) / 5);
    z-index: 1000000;
  }

  &.-mechanic {
    background-color: var(--color-bg-mechanic);
  }

  &.-highlighted {
    border-color: var(--color-due-date);
  }

  &.-car.-expanded {
    height: 12.8rem;
  }

  &.-work.-expanded {
    height: 14.4rem;
  }

  &.-deleted {
    border-color: var(--color-border-warning);

    &.-expanded {
      height: 6.8rem;
    }
  }

  &.-with-note {
    height: 3.6rem;

    &.-work.-expanded {
      height: 16rem;
    }

    &.-car.-expanded {
      height: 14.2rem;
    }

    &.-deleted.-expanded {
      height: 7.8rem;
    }
  }
}

.calendar-week-day-car-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: .4rem;
}

.calendar-week-day-car-name {
  @include body;
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.car-name {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.-result {
    background-color: var(--search-highlighted-bg);
    color: var(--search-text-color);
    box-shadow: 0 0 4px 1px var(--search-box-shadow-color);
    height: 1.1rem;
    margin-top: .2rem;
    align-items: center;
    display: flex;
  }
}

.calendar-week-day-car-note-container {
  @include body;
  color: var(--color-text-secondary);
  display: flex;
  gap: .4rem;
  width: 100%;
  align-items: center;
}

.calendar-week-day-car-note {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.calendar-week-day-car > .p-tieredmenu-overlay {
  display: none;
}

.calendar-week-day-car-slot {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 1.6rem;
  min-width: 1.6rem;
}

.seriously-damaged-icon {
  color: var(--color-text-warning);
}

.tow-truck-icon {
  width: 1.2rem;
  height: 1.2rem;
  color: var(--color-icon);
}

.calendar-week-day-car.-deleted .calendar-week-day-car-name {
  text-decoration: line-through;
  color: var(--color-text-secondary);
  text-decoration-color: var(--color-text-warning);
}

.calendar-week-day-car.-deleted .calendar-week-day-car-slot {
  opacity: .4;
}

.calendar-week-day-car-name:hover {
  cursor: pointer;
}

.calendar-week-day-car:not(.-expanded):hover {
  background-color: var(--color-button-bg-focus);
}

.calendar-week-day-car.-mechanic:not(.-expanded):hover {
  background-color: var(--color-bg-mechanic-hover);
}
</style>
