import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "calendar-car-followup"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_tooltip = _resolveDirective("tooltip")

  return ($setup.props.car.complete_file || $setup.props.car.repair_order || $setup.props.car.valid_support)
    ? _withDirectives((_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(['calendar-car-followup', $props.car.is_deleted ? '-deleted' : ''])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(['car-followup-point', '-complete-file', $setup.props.car.complete_file ? '' : '-hidden'])
        }, null, 2 /* CLASS */),
        _createElementVNode("div", {
          class: _normalizeClass(['car-followup-point', '-repair-order', $setup.props.car.repair_order ? '' : '-hidden'])
        }, null, 2 /* CLASS */),
        _createElementVNode("div", {
          class: _normalizeClass(['car-followup-point', '-valid-support', $setup.props.car.valid_support ? '' : '-hidden'])
        }, null, 2 /* CLASS */)
      ], 2 /* CLASS */)), [
        [
          _directive_tooltip,
          $setup.carFollowupTooltipOptions,
          void 0,
          { left: true }
        ]
      ])
    : (_openBlock(), _createElementBlock("div", _hoisted_1))
}