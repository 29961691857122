<script setup>
import { CircleArrowOutUpLeft } from "lucide-vue-next"
import Message from "primevue/message"
import { onMounted, ref } from "vue"

const showMessage = ref(false)

onMounted(() => {
  setTimeout(() => {
    showMessage.value = true
  }, 100)
})
</script>

<template>
  <Transition name="fade-slide">
    <Message
      v-if="showMessage"
      class="calendar-message"
      severity="info"
    >
      <CircleArrowOutUpLeft size="18" />
      Appuyez sur la touche ÉCHAP pour quitter le mode report
    </Message>
  </Transition>
</template>

<style scoped>
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.fade-slide-enter-from,
.fade-slide-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
</style>
