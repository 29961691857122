export const serializeCar = (car) => {
  return {
    ...car,
    start_work: car.start_work ? new Date(car.start_work) : null,
    breakage_date: car.breakage_date ? new Date(car.breakage_date) : null,
    expertise_date: car.expertise_date ? new Date(car.expertise_date) : null,
    waiting_date: car.waiting_date ? new Date(car.waiting_date) : null,
    due_date: car.due_date ? new Date(car.due_date) : null,
    first_registration_date: car.first_registration_date ? new Date(car.first_registration_date) : null,
  }
}

export const serializeDelayedWork = (work) => {
  return {
    ...work,
    car: serializeCar(work.car),
    date: new Date(work.date),
  }
}
