import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "calendar-note" }
const _hoisted_2 = { class: "calendar-note-top" }
const _hoisted_3 = { class: "calendar-note-author" }
const _hoisted_4 = { class: "calendar-note-name" }
const _hoisted_5 = {
  key: 0,
  class: "calendar-note-text"
}
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "calendar-note-date" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "calendar-note-avatar",
          style: _normalizeStyle({ backgroundColor: $setup.avatarColor })
        }, _toDisplayString($setup.firstCaseUppered($props.note.author.first_name)) + _toDisplayString($setup.firstCaseUppered($props.note.author.last_name)), 5 /* TEXT, STYLE */),
        _createElementVNode("div", _hoisted_4, _toDisplayString($props.note.author.first_name) + " " + _toDisplayString($props.note.author.last_name), 1 /* TEXT */)
      ]),
      ($props.note.author.id === $setup.calendarStore.currentUser.id || $setup.calendarStore.currentUser.is_admin)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "calendar-note-delete",
            onClick: $setup.handleDeleteNote
          }, [
            _createVNode($setup["X"], {
              size: "24",
              class: "calendar-note-delete-icon"
            })
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    ($props.note.author.id !== $setup.calendarStore.currentUser.id)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString($props.note.text), 1 /* TEXT */))
      : (_openBlock(), _createElementBlock("textarea", {
          key: 1,
          class: "calendar-note-textarea",
          value: $props.note.text,
          maxlength: 50,
          rows: 2,
          placeholder: "Écrivez votre note...",
          onInput: $setup.handleTextAreaInput
        }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_6)),
    _createElementVNode("div", _hoisted_7, _toDisplayString($setup.humanizeDate($props.note.created)), 1 /* TEXT */)
  ]))
}