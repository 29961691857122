import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "car-options" }
const _hoisted_2 = ["href"]
const _hoisted_3 = {
  key: 8,
  class: "car-option-button"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[13] || (_cache[13] = _createElementVNode("div", { class: "car-options-delimiter" }, null, -1 /* HOISTED */)),
    ($setup.isDelayedWork && !$setup.car.is_deleted)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "car-option-button",
          onClick: _cache[0] || (_cache[0] = $event => ($setup.updateDelayedWork($setup.props.object)))
        }, [
          _createVNode($setup["CalendarClock"], {
            size: "18",
            class: "car-option-icon"
          }),
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "car-option-text" }, " Heures Reportées ", -1 /* HOISTED */))
        ]))
      : _createCommentVNode("v-if", true),
    (!$setup.car.is_deleted)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: "car-option-button",
          onClick: _cache[1] || (_cache[1] = $event => ($setup.openCarFolder($setup.props.object)))
        }, [
          _createVNode($setup["Pencil"], {
            size: "18",
            class: "car-option-icon"
          }),
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "car-option-text" }, " Dossier ", -1 /* HOISTED */))
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.car.is_deleted)
      ? (_openBlock(), _createElementBlock("button", {
          key: 2,
          class: "car-option-button",
          onClick: $setup.rescheduleCar
        }, [
          _createVNode($setup["Pencil"], {
            size: "18",
            class: "car-option-icon"
          }),
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "car-option-text" }, " Reprogrammer ", -1 /* HOISTED */))
        ]))
      : _createCommentVNode("v-if", true),
    (!$setup.car.is_deleted)
      ? (_openBlock(), _createElementBlock("button", {
          key: 3,
          class: "car-option-button",
          onClick: $setup.changeCalendarDate
        }, [
          _createVNode($setup["Calendar"], {
            size: "18",
            class: "car-option-icon"
          }),
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "car-option-text" }, " Replanifier ", -1 /* HOISTED */))
        ]))
      : _createCommentVNode("v-if", true),
    (!$setup.car.is_deleted)
      ? (_openBlock(), _createElementBlock("button", {
          key: 4,
          class: "car-option-button",
          onClick: $setup.delayWork
        }, [
          _createVNode($setup["ArrowRight"], {
            size: "18",
            class: "car-option-icon"
          }),
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "car-option-text" }, " Reporter du travail ", -1 /* HOISTED */))
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.isDelayedWork && !$setup.car.is_deleted)
      ? (_openBlock(), _createElementBlock("button", {
          key: 5,
          class: "car-option-button",
          onClick: _cache[2] || (_cache[2] = $event => ($setup.calendarStore.deleteDelayedWork($setup.props.object.work)))
        }, [
          _createVNode($setup["X"], {
            size: "18",
            class: "car-option-icon"
          }),
          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "car-option-text" }, " Annuler le report ", -1 /* HOISTED */))
        ]))
      : _createCommentVNode("v-if", true),
    (!$setup.isDelayedWork && !$setup.car.is_deleted)
      ? (_openBlock(), _createElementBlock("button", {
          key: 6,
          class: "car-option-button",
          onClick: _cache[3] || (_cache[3] = $event => ($setup.carStore.openDeletionDialog($setup.car)))
        }, [
          _createVNode($setup["X"], {
            size: "18",
            class: "car-option-icon"
          }),
          _cache[10] || (_cache[10] = _createElementVNode("div", { class: "car-option-text" }, " Annuler le rendez-vous ", -1 /* HOISTED */))
        ]))
      : _createCommentVNode("v-if", true),
    (!$setup.calendarStore.isHomepage)
      ? (_openBlock(), _createElementBlock("a", {
          key: 7,
          class: "car-option-button",
          href: `${$setup.calendarStore.baseUrl}/vehicule/en-charge/${$setup.car.id}/?back_url=${$setup.calendarStore.baseUrl}/calendrier/?date=${$setup.getIsoString($setup.getCalendarDate($setup.props.object))}`
        }, [
          _createVNode($setup["Folder"], {
            size: "18",
            class: "car-option-icon"
          }),
          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "car-option-text" }, " Détails ", -1 /* HOISTED */))
        ], 8 /* PROPS */, _hoisted_2))
      : (_openBlock(), _createElementBlock("a", _hoisted_3, [
          _createVNode($setup["Folder"], {
            size: "18",
            class: "car-option-icon"
          }),
          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "car-option-text" }, " Détails ", -1 /* HOISTED */))
        ]))
  ]))
}