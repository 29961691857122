<script setup>
const props = defineProps({
  car: {
    type: Object,
    required: true,
  },
})
</script>

<template>
  <div class="car-followup-tooltip">
    <div
      :class="['car-followup-point-container', props.car.complete_file ? '' : '-hidden']"
    >
      <div class="car-followup-point-large -complete-file" />
      Dossier complet
    </div>
    <div
      :class="['car-followup-point-container', props.car.repair_order ? '' : '-hidden']"
    >
      <div class="car-followup-point-large -repair-order" />
      Ordre de réparation prêt
    </div>
    <div
      :class="['car-followup-point-container', props.car.valid_support ? '' : '-hidden']"
    >
      <div class="car-followup-point-large -valid-support" />
      Prise en charge valide
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../../../ui/static_src//ui//base/mixins';

.car-followup-tooltip {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: .2rem;
}

.car-followup-point-container {
  @include body;
  display: flex;
  gap: .4rem;
  align-items: center;
  width: 100%;

  &.-hidden {
    display: none;
  }
}

.car-followup-point-large {
  width: .6rem;
  height: .6rem;
  border-radius: 50%;

  &.-complete-file {
    background-color: var(--car-followup-complete-file);
  }

  &.-repair-order {
    background-color: var(--car-followup-repair-order);
  }

  &.-valid-support {
    background-color: var(--car-followup-valid-support);
  }
}
</style>
