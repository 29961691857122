<script setup>
import Dialog from "primevue/dialog"
import Textarea from "primevue/textarea"
import { useToast } from "primevue/usetoast"

import { useCalendarStore } from "../../../stores/calendar.js"
import { useCarStore } from "../../../stores/car.js"

const carStore = useCarStore()
const calendarStore = useCalendarStore()
const toast = useToast()

const handleDeletion = () => {
  carStore.focusedCar.is_deleted = true
  if (carStore.focusedCar.replacements.length > 0) {
    calendarStore.deleteReplacement({ id: carStore.focusedCar.replacements[0].id })
    carStore.focusedCar.need_replacement = false
    carStore.focusedCar.replacements = []
  }
  calendarStore.updateCarInCharge(carStore.focusedCar).then(() => {
    toast.add({
      severity: "success",
      summary: `${carStore.focusedCar.owner_last_name} | ${carStore.focusedCar.model}`,
      detail: "Le rendez-vous a bien été annulé",
      life: 4000,
    })
  })
  carStore.showDeletionDialog = false
}
</script>

<template>
  <Dialog
    v-model:visible="carStore.showDeletionDialog"
    header="Annuler le rendez-vous"
    modal
  >
    <div class="input-subtitle">
      Vous êtes sur le point d'annuler le rendez-vous pour le véhicule
      <strong>{{ carStore.focusedCar.owner_last_name }} | {{ carStore.focusedCar.model }}</strong>.
    </div>
    <Textarea
      id="background_note"
      v-model="carStore.focusedCar.background_note"
      rows="5"
      cols="30"
      style="resize: none"
      placeholder="Écrire un commentaire"
      class="input-textarea"
      @update:model-value="(value) => carStore.updateCarInCharge('background_note', value)"
    />
    <div class="car-incharge-form-validate">
      <button
        class="-deletion car-incharge-form-button"
        @click="handleDeletion"
      >
        Annuler
      </button>
    </div>
  </Dialog>
</template>

<style lang="scss" scoped>
@import "../../../../../../ui/static_src/ui/base/mixins";

.input-subtitle {
  @include body;
}

.input-textarea {
  color: var(--color-text-primary);
  padding: .4rem;
  border-radius: var(--radius);
  margin-top: 1rem;
  width: 100%;
  background-color: var(--content-bg--color-lighter);
}

.car-incharge-form-validate {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1.4rem;
}

.car-incharge-form-button {
  padding: .4rem;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
