import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Dialog"], {
    visible: $setup.carStore.carInChargeModalOpen,
    "onUpdate:visible": _cache[1] || (_cache[1] = $event => (($setup.carStore.carInChargeModalOpen) = $event)),
    header: $setup.focusedCar.id ? 'Modification du véhicule en charge' : 'Nouveau véhicule en charge',
    modal: ""
  }, {
    default: _withCtx(() => [
      _createVNode($setup["CarInChargeFields"], {
        modelValue: $setup.focusedCar,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.focusedCar) = $event)),
        "handle-validate": $setup.handleValidate,
        loading: $setup.loading,
        onRegistrationCarDataExtracted: $setup.handleRegistrationCarExtractedData
      }, {
        default: _withCtx(() => [
          _createVNode($setup["InsurancesSelecter"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "loading"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible", "header"]))
}