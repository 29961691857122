export const computeWork = (cars, delayedWorks) => {
  const validCars = cars.filter(car => !["expertise", "breakage", "waiting"].includes(car.state) && !car.is_deleted)
  const validDelayedWorks = delayedWorks.filter(work => !["expertise", "breakage", "waiting"].includes(work.car.state))

  return {
    sheetMetalWork: validCars.reduce((acc, car) => acc + car.sheet_metal_work_hours, 0) +
    validDelayedWorks.reduce((acc, work) => acc + work.sheet_metal_work_hours, 0),
    paintWork: validCars.reduce((acc, car) => acc + car.painting_hours, 0) +
    validDelayedWorks.reduce((acc, work) => acc + work.painting_hours, 0),
    mechanicWork: validCars.reduce((acc, car) => acc + car.mechanic_hours, 0) +
    validDelayedWorks.reduce((acc, work) => acc + work.mechanic_hours, 0),
  }
}

const inferWorkStateField = (workType) => {
  switch (workType) {
    case "sheet_metal_work_hours":
      return "sheet_metal_state"
    case "painting_hours":
      return "painting_state"
    case "mechanic_hours":
      return "mechanic_state"
  }
}

const getCarDoneWork = (car, workType) => {
  const workTypeState = inferWorkStateField(workType)
  if (car.delayed_works.length > 0 ||
    (car.state === "repairing" && car[workTypeState] === "finished") ||
    ["reassembly", "finished", "delivered"].includes(car.state)) {
    return car[workType]
  }
  return 0
}

const getDelayedWorkDoneWork = (work, workType) => {
  const car = work.car
  const workTypeState = inferWorkStateField(workType)
  if (!work.is_last || (car.state === "repairing" && car[workTypeState] === "finished") || ["reassembly", "finished", "delivered"].includes(car.state)) {
    return work[workType]
  }
  return 0
}

export const computeDoneWork = (cars, delayedWorks) => {
  const validCars = cars.filter(car => !["expertise", "breakage", "waiting"].includes(car.state) && !car.is_deleted)
  const validDelayedWorks = delayedWorks.filter(work => !["expertise", "breakage", "waiting"].includes(work.car.state))

  return {
    sheetMetalWork: validCars.reduce((acc, car) => acc + getCarDoneWork(car, "sheet_metal_work_hours"), 0) +
    validDelayedWorks.reduce((acc, work) => acc + getDelayedWorkDoneWork(work, "sheet_metal_work_hours"), 0),
    paintWork: validCars.reduce((acc, car) => acc + getCarDoneWork(car, "painting_hours"), 0) +
    validDelayedWorks.reduce((acc, work) => acc + getDelayedWorkDoneWork(work, "painting_hours"), 0),
    mechanicWork: validCars.reduce((acc, car) => acc + getCarDoneWork(car, "mechanic_hours"), 0) +
    validDelayedWorks.reduce((acc, work) => acc + getDelayedWorkDoneWork(work, "mechanic_hours"), 0),
  }
}

export const getStateDate = (car) => {
  switch (car.state) {
    case "breakage":
      return car.breakage_date
    case "expertise":
      return car.expertise_date
    case "waiting":
      return car.waiting_date
    default:
      return car.start_work
  }
}

export const getStateList = () => {
  return [
    { value: "coming", label: "À venir" },
    { value: "todo", label: "À faire" },
    { value: "breakage", label: "Casse" },
    { value: "expertise", label: "Expertise" },
    { value: "waiting", label: "En attente" },
    { value: "repairing", label: "Réparation" },
    { value: "reassembly", label: "Remontage" },
    { value: "finished", label: "Terminé" },
    { value: "delivered", label: "Livré" },
  ]
}

export const getRepairingStateList = () => {
  return [
    { value: "todo", label: "À faire" },
    { value: "in-progress", label: "En cours" },
    { value: "finished", label: "Terminé" },
  ]
}

export const stateName = (state) => {
  return getStateList().find(s => s.value === state).label
}

export const carObject = (object) => object.type === "car" ? object.car : object.work.car

export const getCalendarDate = (obj) => {
  if (obj.type === "car") {
    if (obj.car.delayed_works.length > 0) {
      return new Date(obj.car.delayed_works.find(work => work.is_last).date)
    }
    return getStateDate(obj.car)
  }
  return new Date(obj.work.date)
}

export const isOnlyMechanic = (car) => {
  return (
    car.sheet_metal_work_hours + car.delayed_works.reduce((acc, work) => acc + work.sheet_metal_work_hours, 0) === 0 &&
    car.painting_hours + car.delayed_works.reduce((acc, work) => acc + work.painting_hours, 0) === 0 &&
    car.mechanic_hours + car.delayed_works.reduce((acc, work) => acc + work.mechanic_hours, 0) > 0
  )
}
