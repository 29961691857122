import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "insurance-selecter" }
const _hoisted_2 = { class: "insurance-selecter -footer" }
const _hoisted_3 = { class: "insurance-box-container" }
const _hoisted_4 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["AutoComplete"], {
      modelValue: $setup.query,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.query) = $event)),
      suggestions: $setup.insurances,
      placeholder: "Rechercher une assurance",
      delay: 300,
      onComplete: $setup.handleInsuranceSearch,
      onItemSelect: $setup.handleInsuranceSelect
    }, {
      option: _withCtx((slotProps) => [
        _createElementVNode("div", _hoisted_1, _toDisplayString(slotProps.option.name), 1 /* TEXT */)
      ]),
      empty: _withCtx(() => _cache[2] || (_cache[2] = [
        _createElementVNode("div", { class: "insurance-selecter -empty" }, " Aucune assurance ne correspond à votre recherche ", -1 /* HOISTED */)
      ])),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            class: "insurance-add",
            onClick: _cache[0] || (_cache[0] = $event => ($setup.calendarStore.insuranceModalOpen = true))
          }, [
            _createVNode($setup["Plus"], { size: "18" }),
            _cache[3] || (_cache[3] = _createTextVNode(" Créer une assurance "))
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "suggestions"]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.carStore.focusedCar.insurances, (insurance) => {
        return (_openBlock(), _createElementBlock("div", {
          key: insurance.id,
          class: "insurance-box"
        }, [
          _createTextVNode(_toDisplayString(insurance.name) + " ", 1 /* TEXT */),
          _createElementVNode("button", {
            class: "remove-insurance",
            onClick: $event => ($setup.carStore.removeInsurance(insurance))
          }, [
            _createVNode($setup["X"], { size: "18" })
          ], 8 /* PROPS */, _hoisted_4)
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ], 64 /* STABLE_FRAGMENT */))
}