<script setup>
import { DatePicker } from "primevue"
import Dialog from "primevue/dialog"
import { ref, watch } from "vue"

import FloatLabel from "primevue/floatlabel"

const emit = defineEmits(["update:modelValue"])

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  object: {
    type: Object,
    required: true,
  },
  car: {
    type: Object,
    required: true,
  },
  handleValidate: {
    type: Function,
    required: true,
  },
})

const arrivalDate = ref(props.car.waiting_date ? new Date(props.car.waiting_date) : new Date())
const localOpenModal = ref(props.modelValue)

watch(() => props.modelValue, (newVal) => {
  localOpenModal.value = newVal
})

watch(localOpenModal, (newVal) => {
  emit("update:modelValue", newVal)
})
</script>

<template>
  <Dialog
    v-model:visible="localOpenModal"
    header="Depuis quelle date le véhicule est-il en attente ?"
    modal
  >
    <div class="calendar-week-day-arrival-date-selecter">
      <FloatLabel variant="on">
        <DatePicker
          id="waiting_date"
          v-model="arrivalDate"
          style="min-width: 13rem;"
          date-format="dd/mm/yy"
          required
        />
        <label for="start_work">En attente depuis</label>
      </FloatLabel>
    </div>
    <div class="submit-container">
      <button
        class="submit"
        :disabled="!arrivalDate"
        @click="props.handleValidate(arrivalDate)"
      >
        Enregistrer
      </button>
    </div>
  </Dialog>
</template>

<style lang="scss" scoped>
.calendar-week-day-arrival-date-selecter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  height: 4rem;
  width: 30rem;
}
</style>
