import { defineStore } from "pinia"

const createBaseCarInChargeModel = () => {
  return {
    registration: null,
    brand: null,
    model: null,
    state: "coming",
    start_work: null,
    sheet_metal_work_hours: 0,
    painting_hours: 0,
    mechanic_hours: 0,
    sheet_metal_state: "todo",
    painting_state: "todo",
    mechanic_state: "todo",
    owner_first_name: null,
    owner_last_name: null,
    owner_phone_number: null,
    owner_phone_number_2: null,
    owner_email: null,
    owner_address: null,
    first_registration_date: null,
    due_date: null,
    breakage_date: null,
    expertise_date: null,
    waiting_date: null,
    invoice_ready: false,
    need_replacement: false,
    complete_file: false,
    repair_order: false,
    valid_support: false,
    is_deleted: false,
    foreground_note: "",
    background_note: "",
    from_tow_truck: false,
    wreck_delivered: false,
    is_seriously_damaged: false,
    registration_car: null,
    company_id: window.CALENDAR_ENV.company_pk,
    insurances: [],
    replacements: [],
    delayed_works: [],
  }
}

const createBaseWorkModel = () => {
  return {
    car: null,
    date: null,
    sheet_metal_work_hours: 0,
    painting_hours: 0,
    mechanic_hours: 0,
    company_id: window.CALENDAR_ENV.company_pk,
    is_last: true,
  }
}

export const useCarStore = defineStore("car", {
  state: () => ({
    focusedCar: createBaseCarInChargeModel(),
    focusedWork: createBaseWorkModel(),
    carInChargeModalOpen: false,
    showDeletionDialog: false,
    delayedWorkModalOpen: false,
    creatingDelayedWork: false,
    updatingDelayedWork: false,
    updatingCar: false,
    draggingCar: null,
    draggingCarStartPosition: { x: 0, y: 0 },
    draggingWork: null,
    draggingWorkStartPosition: { x: 0, y: 0 },
  }),
  actions: {
    openCarInChargeForm(car, day = null) {
      this.updatingCar = day === null
      this.focusedCar = createBaseCarInChargeModel()
      if (!car) {
        this.focusedCar.start_work = day
        this.focusedCar.breakage_date = day
        this.focusedCar.expertise_date = day
        this.focusedCar.waiting_date = day
      } else {
        Object.assign(this.focusedCar, car)
      }
      this.carInChargeModalOpen = true
    },
    updateCarInCharge(property, value, event = null) {
      if (event) {
        event.preventDefault()
        event.stopPropagation()
      }

      this.focusedCar[property] = value
    },
    addInsurance(insurance) {
      this.focusedCar.insurances.push(insurance)
    },
    removeInsurance(insurance) {
      const index = this.focusedCar.insurances.findIndex(i => i.id === insurance.id)
      this.focusedCar.insurances.splice(index, 1)
    },
    addReplacement(replacement) {
      this.focusedCar.replacements = [replacement]
    },
    removeReplacement() {
      this.focusedCar.replacements = []
    },
    openDeletionDialog(car) {
      this.showDeletionDialog = true
      this.focusedCar = car
    },
    setDraggingCar(car, event) {
      this.draggingCarStartPosition = { x: event.clientX, y: event.clientY }
      this.draggingCar = createBaseCarInChargeModel()
      Object.assign(this.draggingCar, car)
    },
    setDraggingWork(work, event) {
      this.draggingWorkStartPosition = { x: event.clientX, y: event.clientY }
      this.draggingWork = createBaseWorkModel()
      Object.assign(this.draggingWork, work)
    },
  },
})
