import { ref, watch } from "vue"

export function useComputedRef(computedValue) {
  const currentValue = ref(computedValue.value)

  watch(computedValue, (newValue) => {
    if (JSON.stringify(newValue) !== JSON.stringify(currentValue.value)) {
      currentValue.value = newValue
    }
  })

  return currentValue
}
