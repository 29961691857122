import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "calendar-toolbar" }
const _hoisted_2 = { class: "calendar-toolbar-left" }
const _hoisted_3 = { class: "calendar-car-search" }
const _hoisted_4 = { class: "calendar-week-day-car-name" }
const _hoisted_5 = { class: "car-name" }
const _hoisted_6 = { class: "calendar-week-day-car-slot" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "tow-truck-icon" }
const _hoisted_10 = ["href"]
const _hoisted_11 = { class: "calendar-week-day-car-slot" }
const _hoisted_12 = ["href"]
const _hoisted_13 = { class: "car-calendar-date" }
const _hoisted_14 = { for: "car_in_charge_in_calendar" }
const _hoisted_15 = { class: "calendar-toolbar-right" }
const _hoisted_16 = { class: "calendar-buttons" }
const _hoisted_17 = {
  key: 0,
  class: "notification-count"
}
const _hoisted_18 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["FloatLabel"], {
        variant: "on",
        class: "toolbar"
      }, {
        default: _withCtx(() => [
          _createVNode($setup["AutoComplete"], {
            id: "car_in_charge_in_calendar",
            ref: "autoCompleteRef",
            modelValue: $setup.query,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.query) = $event)),
            style: {"width":"15rem"},
            suggestions: $setup.searchResults,
            onFocus: $setup.onFocus,
            onItemSelect: $setup.handleSelect
          }, {
            option: _withCtx((slotProps) => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", {
                  class: _normalizeClass(`car__card-state -${slotProps.option.state}`)
                }, null, 2 /* CLASS */),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("span", _hoisted_5, _toDisplayString(slotProps.option.owner_last_name) + " | " + _toDisplayString(slotProps.option.model), 1 /* TEXT */)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  (slotProps.option.is_seriously_damaged)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                        _createVNode($setup["Flame"], {
                          size: "18",
                          class: "seriously-damaged-icon"
                        })
                      ]))
                    : (slotProps.option.from_tow_truck)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                          (_openBlock(), _createElementBlock("svg", _hoisted_9, [
                            _createElementVNode("use", {
                              href: $setup.svgLink('cars', 'tow-truck')
                            }, null, 8 /* PROPS */, _hoisted_10)
                          ]))
                        ]))
                      : _createCommentVNode("v-if", true)
                ]),
                _createElementVNode("div", _hoisted_11, [
                  (slotProps.option.need_replacement)
                    ? (_openBlock(), _createElementBlock("svg", {
                        key: 0,
                        class: _normalizeClass(['replacement-car-icon', {'-finished': $setup.isReplacementFinished(slotProps.option)}])
                      }, [
                        _createElementVNode("use", {
                          href: $setup.svgLink('cars', 'car-replacement')
                        }, null, 8 /* PROPS */, _hoisted_12)
                      ], 2 /* CLASS */))
                    : _createCommentVNode("v-if", true)
                ]),
                _createVNode($setup["CalendarCarFollowUp"], {
                  car: slotProps.option
                }, null, 8 /* PROPS */, ["car"]),
                _createElementVNode("div", _hoisted_13, _toDisplayString($setup.getFullDate($setup.getCalendarDate({type: "car", car:slotProps.option}))), 1 /* TEXT */)
              ])
            ]),
            empty: _withCtx(() => _cache[3] || (_cache[3] = [
              _createElementVNode("div", { class: "car-search-empty" }, " Aucun véhicule ne correspond à votre recherche ", -1 /* HOISTED */)
            ])),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue", "suggestions"]),
          _createElementVNode("label", _hoisted_14, [
            _createVNode($setup["Search"], { size: "14" }),
            _cache[4] || (_cache[4] = _createTextVNode(" Rechercher un véhicule "))
          ])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createVNode($setup["DatePicker"], {
        modelValue: $setup.currentMonth,
        "onUpdate:modelValue": [
          _cache[1] || (_cache[1] = $event => (($setup.currentMonth) = $event)),
          $setup.handleChangeMonth
        ],
        view: "month",
        style: {"width":"10rem"},
        "show-icon": ""
      }, null, 8 /* PROPS */, ["modelValue"]),
      _createElementVNode("div", _hoisted_16, [
        _withDirectives((_openBlock(), _createElementBlock("button", {
          class: "-highlighted calendar-button",
          onClick: _cache[2] || (_cache[2] = $event => ($setup.calendarStore.notificationsModalOpen = true))
        }, [
          _createVNode($setup["Inbox"], { size: "24" }),
          ($setup.notificationsCount > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString($setup.notificationsCount), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ])), [
          [
            _directive_tooltip,
            'Notifications',
            void 0,
            { left: true }
          ]
        ]),
        _withDirectives((_openBlock(), _createElementBlock("a", {
          class: "-highlighted calendar-button",
          href: `${$setup.calendarStore.baseUrl}/calendrier/`
        }, [
          _createVNode($setup["Clock"], { size: "24" })
        ], 8 /* PROPS */, _hoisted_18)), [
          [
            _directive_tooltip,
            'Aller à aujourd\'hui',
            void 0,
            { left: true }
          ]
        ])
      ])
    ])
  ]))
}