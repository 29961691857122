<script setup>
import { MessageSquarePlus } from "lucide-vue-next"
import SplitButton from "primevue/splitbutton"
import { computed } from "vue"

import CalendarWeekChargeChart from "./CalendarWeekChargeChart.vue"
import CalendarWeekDoneWorkChart from "./CalendarWeekDoneWorkChart.vue"

import { useCalendarStore } from "../../stores/calendar.js"
import { getFullDate, getNextFriday } from "../../utils/date.js"
import { svgLink } from "../../utils/svg.js"

const props = defineProps({
  monday: {
    type: Date,
    required: true,
  },
  cars: {
    type: Array,
    required: true,
  },
  delayedWorks: {
    type: Array,
    required: true,
  },
  replacementCapacity: {
    type: Number,
    required: true,
  },
  weekCapacity: {
    type: Object,
    required: true,
  },
  delayAnimation: {
    type: Boolean,
    required: false,
  },
})
const friday = getNextFriday(props.monday)

const calendarStore = useCalendarStore()
const carsNeedReplacementCount = computed(() => props.cars.filter(car => car.need_replacement).length)
const delayedWorksNeedReplacementCount = computed(() => props.delayedWorks.filter(work => work.car.need_replacement).length)

const changeStatus = (full) => {
  const currentMonday = props.monday
  calendarStore.markFullWeek(currentMonday, { full })
}

const createNote = () => {
  const currentMonday = props.monday
  calendarStore.createNote(currentMonday)
}
</script>

<template>
  <div class="calendar-week-header">
    <div class="calendar-week-details">
      <div class="calendar-week-dates">
        {{ getFullDate(props.monday) }} - {{ getFullDate(friday) }}
      </div>
      <div class="calendar-week-tools">
        <div
          v-tooltip.bottom="'Véhicule emprunté cette semaine'"
          class="calendar-week-replacement-count"
        >
          <svg class="calendar-week-tools-icon">
            <use :href="svgLink('cars', 'car-replacement')" />
          </svg>
          {{ carsNeedReplacementCount + delayedWorksNeedReplacementCount }} / {{ props.replacementCapacity }}
        </div>
        <button
          v-tooltip.bottom="{ value: 'Ajouter une note', showDelay: 1000 }"
          class="calendar-week-tools-button"
          @click="createNote"
        >
          <MessageSquarePlus
            size="24"
          />
        </button>
      </div>
    </div>
    <CalendarWeekChargeChart
      :cars="cars"
      :delayed-works="delayedWorks"
      :week-capacity="weekCapacity"
      :delay-animation="props.delayAnimation"
    />
    <CalendarWeekDoneWorkChart
      :cars="cars"
      :delayed-works="delayedWorks"
      :week-capacity="weekCapacity"
      :delay-animation="props.delayAnimation"
    />
    <div
      v-if="!calendarStore.isHomepage"
      class="calendar-week-status"
    >
      <SplitButton
        v-if="calendarStore.isFullWeek(props.monday)"
        class="-full"
        label="COMPLET"
        icon="pi pi-times"
        dropdown-icon="pi pi-chevron-down"
        :model="[{ label: 'INCOMPLET', icon: 'pi pi-check', class: '-incomplete', command: () => changeStatus(false) }]"
      />
      <SplitButton
        v-else
        class="-incomplete"
        label="INCOMPLET"
        icon="pi pi-check"
        dropdown-icon="pi pi-chevron-down"
        :model="[{ label: 'COMPLET', icon: 'pi pi-times', class: '-full', command: () => changeStatus(true) }]"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../../../ui/static_src/ui/base/mixins";

.calendar-week-header {
  display: grid;
  flex-direction: row;
  align-items: start;
  grid-template-columns: 21rem 2fr 2fr 1fr;
  gap: 0.5rem;
  width: 100%;
}

.calendar-week-status {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1rem;
}

.calendar-week-details {
  @include title;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.calendar-week-replacement-count {
  @include subtitle;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.calendar-week-tools {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.calendar-week-tools-icon {
  width: 1.5rem;
  height: 1.5rem;
  color: var(--color-primary);
}

.calendar-week-tools-button {
  color: var(--color-icon);

  &:hover {
    color: var(--color-icon-focus);
  }
}
</style>
