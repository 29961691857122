<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from "vue"
import { sortBy } from "lodash-es"
import { useToast } from "primevue/usetoast"
import { PlusIcon } from "lucide-vue-next"

import CalendarCar from "./CalendarCar.vue"
import CalendarChargeChart from "./CalendarChargeChart.vue"

import { getDateDayName, getNextFriday, getIsoString, getFullDate } from "../../utils/date.js"
import { computeWork, getStateDate, isOnlyMechanic } from "../../utils/cars.js"
import { useCarStore } from "../../stores/car.js"
import { useCalendarStore } from "../../stores/calendar.js"
import { useComputedRef } from "../../composables/useComputedRef.js"
import { updateCarInCharge, updateReplacement, getReplacementsOfWeek } from "../../services/api.js"

const props = defineProps({
  day: {
    type: Date,
    required: true,
  },
  cars: {
    type: Array,
    required: true,
  },
  delayedWorks: {
    type: Array,
    required: true,
  },
  dayCapacity: {
    type: Object,
    required: true,
  },
  delayAnimation: {
    type: Boolean,
    required: false,
  },
  publicHolidayName: {
    type: String,
    default: null,
  },
})

const STATE_ORDER = {
  coming: 1,
  expertise: 2,
  todo: 3,
  repairing: 4,
  reassembly: 5,
  finished: 6,
  delivered: 7,
  waiting: 8,
}

const carOrder = (object) => {
  const carObject = object.type === "work" ? object.work.car : object.car
  const stateCount = Object.keys(STATE_ORDER).length

  if (carObject.is_deleted) {
    return 2 * stateCount + 3 + STATE_ORDER[carObject.state]
  }

  if (carObject.state === "breakage") {
    return 2 * stateCount + 2
  }

  if (isOnlyMechanic(carObject)) {
    return stateCount + STATE_ORDER[carObject.state]
  }

  return STATE_ORDER[carObject.state]
}

const carStore = useCarStore()
const toast = useToast()
const calendarStore = useCalendarStore()
const dayRef = ref(null)
const clickCount = ref(0)
const dayWork = computed(() => computeWork(props.cars, props.delayedWorks))
const capacity = computed(() => ({
  sheetMetalWork: props.dayCapacity.sheet_metal_work,
  paintWork: props.dayCapacity.painting,
  mechanicWork: props.dayCapacity.mechanic,
}))

const slots = computed(() => {
  const slots = []
  props.cars.forEach((car) => {
    slots.push({
      type: "car",
      display: car.delayed_works.length === 0 && car.id !== carStore.draggingCar?.id,
      car,
    })
  })
  props.delayedWorks.forEach((work) => {
    slots.push({
      type: "work",
      display: work.is_last && work.id !== carStore.draggingWork?.id,
      work,
    })
  })
  return sortBy(slots, carOrder)
})

const currentSlots = useComputedRef(slots)

const openCarInChargeForm = () => {
  carStore.openCarInChargeForm(null, props.day)
}

const updateCalendarReplacement = async(replacement) => {
  const monday = getIsoString(props.day)
  const { data } = await getReplacementsOfWeek(monday, window.CALENDAR_ENV.company_pk)
  const nextReplacement = data.find(r => r.replacement_car.id === replacement.replacement_car.id && r.id !== replacement.id)
  if (nextReplacement) calendarStore.deleteReplacement(nextReplacement)
  await updateReplacement({ ...replacement, end_date: getIsoString(replacement.end_date), start_date: getIsoString(replacement.start_date) })
  toast.add({
    severity: "success",
    summary: `${replacement.car_incharge.owner_last_name} | ${replacement.car_incharge.model}`,
    detail: `Le remplacement du véhicule ${replacement.car_incharge.owner_last_name} | ${replacement.car_incharge.model} a été marqué du ${getFullDate(replacement.start_date)} au ${getFullDate(replacement.end_date)}`,
    life: 4000,
  })
  if (nextReplacement) {
    toast.add({
      severity: "warn",
      summary: `${nextReplacement.car_incharge.owner_last_name} | ${nextReplacement.car_incharge.model}`,
      detail: `L'emprunt du ${getFullDate(nextReplacement.start_date)} au ${getFullDate(nextReplacement.end_date)} a été supprimé`,
      life: 4000,
    })
  }
}

const isOutOfDueDate = computed(() => {
  if (carStore.draggingCar && carStore.draggingCar?.due_date) {
    return carStore.draggingCar.due_date.getTime() <= props.day.getTime()
  }
  if (carStore.draggingWork && carStore.draggingWork.car.due_date) {
    return new Date(carStore.draggingWork.car.due_date).getTime() <= props.day.getTime()
  }
  return false
})

const selectDay = () => {
  if (props.publicHolidayName) return
  if (carStore.draggingCar) {
    if (getStateDate(carStore.draggingCar).getTime() === props.day.getTime()) {
      clickCount.value = (clickCount.value + 1) % 2
    }
    if (clickCount.value === 0) {
      const car = calendarStore.cars.find((car) => car.id === carStore.draggingCar.id)
      if (car?.due_date && car.due_date.getTime() <= getStateDate(car).getTime()) {
        toast.add({
          severity: "warn",
          summary: `${car.owner_last_name} | ${car.model}`,
          detail: `La date limite du véhicule du ${car.due_date.toLocaleDateString()} a été supprimée`,
          life: 4000,
        })
        car.due_date = null
      }
      if (car.replacements.length > 0) {
        const newReplacement = { ...car.replacements[0], end_date: getNextFriday(props.day), start_date: props.day }
        updateCalendarReplacement(newReplacement).then(() => calendarStore.updateCarInCharge(car))
      } else calendarStore.updateCarInCharge(car)
      carStore.draggingCar = null
      clickCount.value = 0
    }
  }
  if (carStore.draggingWork) {
    if (carStore.draggingWork.date.getTime() === props.day.getTime()) {
      clickCount.value = (clickCount.value + 1) % 2
    }
    if (clickCount.value === 0) {
      const work = calendarStore.delayedWorks.find((work) => work.id === carStore.draggingWork.id)
      const dueDateNotRespectedAnymore = work.car.due_date && new Date(work.car.due_date).getTime() <= work.date.getTime()
      if (dueDateNotRespectedAnymore) {
        const car = work.car
        updateCarInCharge({ ...car, due_date: null, insurances: car.insurances.map((insurance) => insurance.id) })
        toast.add({
          severity: "warn",
          summary: `${work.car.owner_last_name} | ${work.car.model}`,
          detail: `La date limite du véhicule du ${new Date(car.due_date).toLocaleDateString()} a été supprimée`,
          life: 4000,
        })
      }
      let newReplacement = null
      if (work.car.replacements.length > 0) {
        newReplacement = { ...work.car.replacements[0], end_date: getNextFriday(props.day) }
        updateCalendarReplacement(newReplacement)
      }
      if (carStore.creatingDelayedWork) {
        calendarStore.updateDelayedWork(work).then((w) => {
          carStore.creatingDelayedWork = false
          if (dueDateNotRespectedAnymore) w.car.due_date = null
          if (newReplacement) w.car.replacements = [newReplacement]
          toast.add({
            severity: "success",
            summary: `${w.car.owner_last_name} | ${w.car.model}`,
            detail: "Le travail a été reporté",
            life: 4000,
          })
        })
      } else {
        calendarStore.updateDelayedWork(work).then((w) => {
          if (dueDateNotRespectedAnymore) w.car.due_date = null
          if (newReplacement) w.car.replacements = [newReplacement]
        })
      }
      carStore.draggingWork = null
      carStore.focusedWork.car = null
      clickCount.value = 0
    }
  }
}

const updateDraggingCarStateDate = () => {
  if (carStore.draggingCar) calendarStore.addNewDraggingCarStateDate(carStore.draggingCar.id, props.day)
  if (carStore.draggingWork) calendarStore.addNewDraggingWorkDate(carStore.draggingWork.id, props.day)
}

const removeDraggingCarStateDate = () => {
  if (carStore.draggingCar) calendarStore.removeDraggingCarStateDate(carStore.draggingCar.id)
  if (carStore.draggingWork) calendarStore.removeDraggingWorkDate(carStore.draggingWork.id, props.day)
}

watch([() => carStore.draggingCar, () => carStore.draggingWork], () => {
  if (!carStore.draggingCar && !carStore.draggingWork) clickCount.value = 0
})

onMounted(() => {
  if (dayRef.value) {
    dayRef.value.addEventListener("mouseenter", updateDraggingCarStateDate)
    dayRef.value.addEventListener("mouseleave", removeDraggingCarStateDate)
  }
})

onUnmounted(() => {
  if (dayRef.value) {
    dayRef.value.removeEventListener("mouseenter", updateDraggingCarStateDate)
    dayRef.value.removeEventListener("mouseleave", removeDraggingCarStateDate)
  }
})
</script>

<template>
  <div
    ref="dayRef"
    :class="['calendar-week-day', {'-limit': isOutOfDueDate }, { '-focusable': carStore.draggingCar || carStore.draggingWork }]"
    @click="selectDay"
  >
    <div class="calendar-week-day-name">
      {{ getDateDayName(props.day) }}
    </div>
    <div
      v-if="!publicHolidayName"
      class="calendar-week-day-work"
    >
      <CalendarChargeChart
        :work="dayWork"
        :capacity="capacity"
        font-size-value="small"
        :delay-animation="props.delayAnimation"
      />
    </div>
    <div
      v-if="!publicHolidayName"
      class="calendar-week-day-cars"
    >
      <CalendarCar
        v-for="slot in currentSlots.filter(slot => slot.display)"
        :key="slot.type === 'car' ? slot.car.id : slot.work.car.id"
        :object="slot"
      />
      <button
        class="add-car-button"
        @click="openCarInChargeForm"
      >
        <PlusIcon
          size="20"
          class="add-car-button-icon"
        />
      </button>
    </div>
    <div
      v-else
      class="calendar-week-holidays-message"
    >
      {{ publicHolidayName }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../../../ui/static_src/ui/base/mixins";

.calendar-week-day {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-radius: var(--radius);
  padding: 0.4rem 0.2rem;
  gap: .2rem;
  transition: transform 0.2s, box-shadow 0.2s, background-color 0.2s, z-index 0.2s;
  height: fit-content;
  z-index: 1;

  &.-limit {
    border: 1px solid var(--color-border-warning);
  }

  &.-focusable:hover {
    cursor: pointer;
    transform: translateZ(30px);
    box-shadow: -2px -2px 40px black;
    z-index: 9000;
    background-color: var(--content-bg--color-light);
  }
}

.calendar-week-day-name {
  @include body;
  text-align: center;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 998;
  background-color: var(--content-bg--color-light);
}

.calendar-week-day.-focusable:hover .calendar-week-day-name {
  z-index: inherit;
}

.calendar-week-day.-focusable .calendar-week-day-work,
.calendar-week-day.-focusable .calendar-week-day-cars {
  pointer-events: none;
}

.calendar-week-day-work {
  display: block;
  width: 100%;
  height: 5rem;
}

.calendar-week-day-cars {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: 100%;
}

.add-car-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.4px solid var(--color-border-tertiary);
  border-radius: var(--radius);
  height: 1.6rem;
}

.add-car-button-icon {
  color: var(--color-icon);
}

.add-car-button:hover .add-car-button-icon {
  color: var(--color-icon-focus);
}

.calendar-week-holidays-message {
  margin-top: 1rem;
  @include subtitle;
  color: var(--color-text-secondary);
}
</style>
