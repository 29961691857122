<script setup>
import { useDebounceFn } from "@vueuse/core"
import { watch, ref } from "vue"
import { X } from "lucide-vue-next"

import { useCalendarStore } from "../../stores/calendar.js"
import { humanizeDate } from "../../utils/date.js"
import { firstCaseUppered } from "../../utils/string.js"

const props = defineProps({
  monday: {
    type: Date,
    required: true,
  },
  note: {
    type: Object,
    required: true,
  },
})

const avatarColor = props.note.author.avatar_color
const calendarStore = useCalendarStore()
const noteContent = ref(props.note.text)

const updateNoteDebounce = useDebounceFn((text) => {
  const currentMonday = props.monday
  calendarStore.updateNote(currentMonday, props.note.id, text)
}, 1000)

const handleTextAreaInput = (event) => {
  const textarea = event.target
  const lines = textarea.value.split("\n")

  if (lines.length > 2) {
    textarea.value = lines.slice(0, 2).join("\n")
  }

  noteContent.value = textarea.value
}

const handleDeleteNote = () => {
  const currentMonday = props.monday
  calendarStore.deleteNote(currentMonday, props.note.id)
}

watch(noteContent, (newVal) => {
  updateNoteDebounce(newVal)
})
</script>

<template>
  <div class="calendar-note">
    <div class="calendar-note-top">
      <div class="calendar-note-author">
        <div
          class="calendar-note-avatar"
          :style="{ backgroundColor: avatarColor }"
        >
          {{ firstCaseUppered(note.author.first_name) }}{{ firstCaseUppered(note.author.last_name) }}
        </div>
        <div class="calendar-note-name">
          {{ note.author.first_name }} {{ note.author.last_name }}
        </div>
      </div>
      <button
        v-if="note.author.id === calendarStore.currentUser.id || calendarStore.currentUser.is_admin"
        class="calendar-note-delete"
        @click="handleDeleteNote"
      >
        <X
          size="24"
          class="calendar-note-delete-icon"
        />
      </button>
    </div>
    <div
      v-if="note.author.id !== calendarStore.currentUser.id"
      class="calendar-note-text"
    >
      {{ note.text }}
    </div>
    <textarea
      v-else
      class="calendar-note-textarea"
      :value="note.text"
      :maxlength="50"
      :rows="2"
      placeholder="Écrivez votre note..."
      @input="handleTextAreaInput"
    />
    <div class="calendar-note-date">
      {{ humanizeDate(note.created) }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../../../ui/static_src/ui/base/mixins";

.calendar-note {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  width: 15rem;
  height: 8rem;
  background-color: var(--color-bg-note);
  padding: .6rem;
  border-radius: var(--radius);
}

.calendar-note-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.calendar-note-author {
  @include body;
  color: var(--color-text-secondary);
  display: flex;
  flex-direction: row;
  gap: .4rem;
}

.calendar-note-avatar {
  @include subbody;
  color: var(--color-text-primary);
  display: flex;
  justify-content: center;
  align-self: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: var(--radius-max);
}

.calendar-note-text {
  @include body;
  overflow-wrap: break-word;
  overflow: hidden;
  text-overflow: clip;
  height: 3.5rem;
}

.calendar-note-textarea {
  @include body;
  overflow-wrap: break-word;
  height: 3.5rem;
  background-color: var(--color-bg-note);
  overflow-y: hidden;
  resize: none;
  border: none;
}

.calendar-note-date {
  @include body;
  text-align: end;
  color: var(--color-text-secondary);
}

.calendar-note-delete {
  padding: 0;
  &:hover {
    background-color: transparent;
  }
}

.calendar-note-delete-icon {
  color: var(--color-icon);
}

.calendar-note-delete:hover .calendar-note-delete-icon {
  color: var(--color-text-warning);
}
</style>
