export const extractRegistrationCarData = async(form) => {
  return await api("POST", "/api/registration-car/extract-data/", null, form)
}

export const deleteRegistrationCar = async(id) => {
  return await api("DELETE", `/api/registration-car/${id}/`)
}
// --- Internals ---

const api = async(method, path, options, body = null) => {
  if (body instanceof FormData) {
    return await apiXHR(method, path, options, body)
  }
  return await apiFetch(method, path, options, body)
}

const apiFetch = async(method, path, options, body = null) => {
  body = body || {}
  let data
  // eslint-disable-next-line compat/compat
  const response = await fetch(path, {
    method: method || "GET",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": window.CSRF_TOKEN,
    },
    body: method === "GET" ? undefined : JSON.stringify(body),
  })
  if (response.ok && !path.includes("format=")) {
    try {
      data = await response.json()
    } catch (e) {
      // No-op
    }
  }
  return { response, data }
}

const apiXHR = async(method, path, options, body = null) => {
  options = options || {}
  let data
  // eslint-disable-next-line compat/compat
  const response = await new Promise((resolve) => {
    const xhr = new XMLHttpRequest()
    xhr.upload.onprogress = options?.onUploadProgress || (() => {})

    xhr.onload = () => { resolve(xhr) }

    xhr.open(method, path, true)
    xhr.setRequestHeader("X-CSRFToken", window.CSRF_TOKEN)
    if (options.token) {
      xhr.setRequestHeader("Authorization", `Token ${options.token}`)
    }
    xhr.send(body)
  })
  try {
    data = JSON.parse(response.responseText)
  } catch (e) {
    // No-op
  }
  return {
    response: { ...response, ok: response.status >= 200 && response.status < 300 },
    data,
  }
}
