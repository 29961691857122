import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "calendar-delayed-work" }
const _hoisted_2 = { class: "submit-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Dialog"], {
    visible: $setup.carStore.delayedWorkModalOpen,
    "onUpdate:visible": _cache[3] || (_cache[3] = $event => (($setup.carStore.delayedWorkModalOpen) = $event)),
    header: "Reporter des heures de travail",
    modal: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode($setup["FloatLabel"], { variant: "on" }, {
          default: _withCtx(() => [
            _createVNode($setup["InputNumber"], {
              id: "sheet_metal_work_hours",
              modelValue: $setup.sheetMetalWorkHours,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.sheetMetalWorkHours) = $event)),
              class: "input-delayed-work",
              "min-fraction-digits": 1,
              locale: "en-US",
              style: {"min-width":"14rem"}
            }, null, 8 /* PROPS */, ["modelValue"]),
            _cache[4] || (_cache[4] = _createElementVNode("label", { for: "sheet_metal_work_hours" }, "Tôlerie", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["FloatLabel"], { variant: "on" }, {
          default: _withCtx(() => [
            _createVNode($setup["InputNumber"], {
              id: "painting_hours",
              modelValue: $setup.paintingHours,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.paintingHours) = $event)),
              class: "input-delayed-work",
              "min-fraction-digits": 1,
              locale: "en-US",
              style: {"min-width":"14rem"}
            }, null, 8 /* PROPS */, ["modelValue"]),
            _cache[5] || (_cache[5] = _createElementVNode("label", { for: "painting_hours" }, "Peinture", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["FloatLabel"], { variant: "on" }, {
          default: _withCtx(() => [
            _createVNode($setup["InputNumber"], {
              id: "mechanic_hours",
              modelValue: $setup.mechanicHours,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.mechanicHours) = $event)),
              class: "input-delayed-work",
              "min-fraction-digits": 1,
              locale: "en-US",
              style: {"min-width":"14rem"}
            }, null, 8 /* PROPS */, ["modelValue"]),
            _cache[6] || (_cache[6] = _createElementVNode("label", { for: "mechanic_hours" }, "Mécanique", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          class: "submit",
          onClick: $setup.handleValidate
        }, _toDisplayString($setup.carStore.creatingDelayedWork ? 'Reporter' : 'Modifier'), 1 /* TEXT */)
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible"]))
}