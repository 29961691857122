import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "cars-list-item-left" }
const _hoisted_3 = { class: "cars-list-item-slot" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "car-name" }
const _hoisted_6 = { class: "car-name" }
const _hoisted_7 = { class: "cars-list-item-right" }
const _hoisted_8 = {
  key: 0,
  class: "car-date"
}
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { class: "tow-truck-icon" }
const _hoisted_11 = ["href"]
const _hoisted_12 = {
  key: 3,
  style: {"width":"1.2rem"}
}
const _hoisted_13 = {
  key: 4,
  class: "car-date"
}
const _hoisted_14 = {
  key: 5,
  class: "car-date"
}
const _hoisted_15 = {
  key: 6,
  class: "car-date"
}
const _hoisted_16 = {
  key: 7,
  class: "car-invoice"
}
const _hoisted_17 = {
  key: 8,
  class: "car-invoice"
}
const _hoisted_18 = {
  key: 9,
  class: "car-works"
}
const _hoisted_19 = {
  key: 10,
  class: "car-works"
}
const _hoisted_20 = {
  key: 11,
  class: "car-followup"
}
const _hoisted_21 = {
  key: 12,
  class: "car-followup"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock("a", {
    class: _normalizeClass([
      'cars-list-item',
      {
        '-first': $props.index === $setup.cars.findIndex(c => c.state === $props.car.state)
      }
    ]),
    href: `${$setup.carsStore.baseUrl}vehicule/en-charge/${$props.car.id}/?back_url=${$setup.carsStore.pageUrl}?${$setup.carsStore.currentQuery}`
  }, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("div", {
        class: _normalizeClass(`car__card-state -${$props.car.state}`)
      }, null, 2 /* CLASS */), [
        [
          _directive_tooltip,
          $setup.stateTooltipOptions,
          void 0,
          { left: true }
        ]
      ]),
      _createElementVNode("div", _hoisted_3, [
        ($props.car.need_replacement)
          ? _withDirectives((_openBlock(), _createElementBlock("svg", {
              key: 0,
              class: _normalizeClass(['replacement-car-icon', {'-finished': $setup.isReplacementFinished}])
            }, [
              _createElementVNode("use", {
                href: $setup.svgLink('cars', 'car-replacement')
              }, null, 8 /* PROPS */, _hoisted_4)
            ], 2 /* CLASS */)), [
              [
                _directive_tooltip,
                $setup.replacementTooltipOptions,
                void 0,
                { left: true }
              ]
            ])
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[0] || (_cache[0] = _createElementVNode("span", null, "Propriétaire:", -1 /* HOISTED */)),
        _createTextVNode(" " + _toDisplayString($props.car.owner_last_name), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[1] || (_cache[1] = _createElementVNode("span", null, "Véhicule:", -1 /* HOISTED */)),
        _createTextVNode(" " + _toDisplayString($props.car.brand) + " " + _toDisplayString($props.car.model), 1 /* TEXT */)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      (['todo', 'coming'].includes($props.car.state))
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _cache[2] || (_cache[2] = _createElementVNode("span", null, "Arrive le", -1 /* HOISTED */)),
            _createTextVNode(" " + _toDisplayString($setup.getFullDate($props.car.start_work)), 1 /* TEXT */)
          ]))
        : _createCommentVNode("v-if", true),
      ($props.car.wreck_delivered)
        ? _withDirectives((_openBlock(), _createBlock($setup["CircleCheck"], {
            key: 1,
            size: "20",
            class: "weck-delivered-icon"
          }, null, 512 /* NEED_PATCH */)), [
            [
              _directive_tooltip,
              `Épave livrée`,
              void 0,
              { top: true }
            ]
          ])
        : _createCommentVNode("v-if", true),
      ($props.car.from_tow_truck && ['waiting', 'breakage'].includes($props.car.state))
        ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_9, [
            (_openBlock(), _createElementBlock("svg", _hoisted_10, [
              _createElementVNode("use", {
                href: $setup.svgLink('cars', 'tow-truck')
              }, null, 8 /* PROPS */, _hoisted_11)
            ]))
          ])), [
            [
              _directive_tooltip,
              `Arrivé par dépannage`,
              void 0,
              { top: true }
            ]
          ])
        : _createCommentVNode("v-if", true),
      (!$props.car.from_tow_truck && ['waiting', 'breakage'].includes($props.car.state))
        ? (_openBlock(), _createElementBlock("span", _hoisted_12))
        : _createCommentVNode("v-if", true),
      ($props.car.state === 'waiting')
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _cache[3] || (_cache[3] = _createElementVNode("span", null, "En attente depuis le", -1 /* HOISTED */)),
            _createTextVNode(" " + _toDisplayString($props.car.waiting_date ? $setup.getFullDate($props.car.waiting_date) : $setup.getFullDate($props.car.start_work)), 1 /* TEXT */)
          ]))
        : _createCommentVNode("v-if", true),
      ($props.car.state === 'breakage')
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            _cache[4] || (_cache[4] = _createElementVNode("span", null, "Passage à la casse le", -1 /* HOISTED */)),
            _createTextVNode(" " + _toDisplayString($setup.getFullDate($props.car.breakage_date)), 1 /* TEXT */)
          ]))
        : _createCommentVNode("v-if", true),
      ($props.car.state === 'expertise')
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _cache[5] || (_cache[5] = _createElementVNode("span", null, "Expertise le", -1 /* HOISTED */)),
            _createTextVNode(" " + _toDisplayString($setup.getFullDate($props.car.expertise_date)), 1 /* TEXT */)
          ]))
        : _createCommentVNode("v-if", true),
      ($props.car.state === 'finished' && !$props.car.invoice_ready)
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, _cache[6] || (_cache[6] = [
            _createElementVNode("span", null, "Facture à préparer", -1 /* HOISTED */)
          ])))
        : _createCommentVNode("v-if", true),
      ($props.car.state === 'finished' && $props.car.invoice_ready)
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
            _createVNode($setup["CircleCheck"], {
              size: "20",
              class: "invoice-ready-icon"
            }),
            _cache[7] || (_cache[7] = _createElementVNode("span", null, "Facture prête", -1 /* HOISTED */))
          ]))
        : _createCommentVNode("v-if", true),
      (['todo', 'coming', 'repairing', 'reassembly'].includes($props.car.state) && $setup.totalWorks > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
            ($props.car.sheet_metal_work_hours > 0)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "car-work -sheet-metal",
                  style: _normalizeStyle({ width: `${$props.car.sheet_metal_work_hours / $setup.totalWorks * 100}%` })
                }, _toDisplayString(Math.floor($props.car.sheet_metal_work_hours * 10) / 10) + " T", 5 /* TEXT, STYLE */))
              : _createCommentVNode("v-if", true),
            ($props.car.painting_hours > 0)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "car-work -paint",
                  style: _normalizeStyle({ width: `${$props.car.painting_hours / $setup.totalWorks * 100}%` })
                }, _toDisplayString(Math.floor($props.car.painting_hours * 10) / 10) + " P", 5 /* TEXT, STYLE */))
              : _createCommentVNode("v-if", true),
            ($props.car.mechanic_hours > 0)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: "car-work -mechanic",
                  style: _normalizeStyle({ width: `${$props.car.mechanic_hours / $setup.totalWorks * 100}%` })
                }, _toDisplayString(Math.floor($props.car.mechanic_hours * 10) / 10) + " M", 5 /* TEXT, STYLE */))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true),
      (['todo', 'coming', 'repairing', 'reassembly'].includes($props.car.state) && $setup.totalWorks === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_19, _cache[8] || (_cache[8] = [
            _createElementVNode("span", null, "Charge horaire non renseignée", -1 /* HOISTED */)
          ])))
        : _createCommentVNode("v-if", true),
      ($props.car.complete_file || $props.car.repair_order || $props.car.valid_support)
        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_20, [
            _createElementVNode("div", {
              class: _normalizeClass(['car-followup-point', '-complete-file', $setup.props.car.complete_file ? '' : '-hidden'])
            }, null, 2 /* CLASS */),
            _createElementVNode("div", {
              class: _normalizeClass(['car-followup-point', '-repair-order', $setup.props.car.repair_order ? '' : '-hidden'])
            }, null, 2 /* CLASS */),
            _createElementVNode("div", {
              class: _normalizeClass(['car-followup-point', '-valid-support', $setup.props.car.valid_support ? '' : '-hidden'])
            }, null, 2 /* CLASS */)
          ])), [
            [
              _directive_tooltip,
              $setup.carFollowupTooltipOptions,
              void 0,
              { left: true }
            ]
          ])
        : (_openBlock(), _createElementBlock("div", _hoisted_21)),
      _createElementVNode("button", {
        class: "cars-list-item-update",
        onClick: $setup.openCarInChargeModal
      }, " Modifier ")
    ])
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}