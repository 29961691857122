import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "notifications-empty"
}
const _hoisted_2 = {
  key: 1,
  class: "notifications"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Dialog"], {
    visible: $setup.calendarStore.notificationsModalOpen,
    "onUpdate:visible": _cache[1] || (_cache[1] = $event => (($setup.calendarStore.notificationsModalOpen) = $event)),
    header: `Notifications${$setup.notificationsCount > 0 ? ` (${$setup.notificationsCount})` : ''}`,
    modal: "",
    onAfterHide: $setup.handleClose
  }, {
    default: _withCtx(() => [
      (!$setup.carsNotification && !$setup.replacementsNotification)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, " Aucune notification "))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            ($setup.carsNotification?.cars?.length > 0)
              ? (_openBlock(), _createBlock($setup["CarsNotUpdated"], {
                  key: 0,
                  cars: $setup.orderBy($setup.carsNotification.cars, 'start_work', 'desc'),
                  "on-select-car": $setup.onSelectCar
                }, null, 8 /* PROPS */, ["cars"]))
              : _createCommentVNode("v-if", true),
            ($setup.replacementsNotification?.replacements?.length > 0)
              ? (_openBlock(), _createBlock($setup["ReplacementNotReturned"], {
                  key: 1,
                  replacements: $setup.replacementsNotification.replacements,
                  "on-select-car": $setup.onSelectCar
                }, null, 8 /* PROPS */, ["replacements"]))
              : _createCommentVNode("v-if", true),
            _createElementVNode("button", {
              class: "notification-read-button",
              onClick: _cache[0] || (_cache[0] = $event => ($setup.calendarStore.notificationsModalOpen = false))
            }, " Lu ")
          ]))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible", "header"]))
}