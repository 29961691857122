import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "notification" }
const _hoisted_2 = { class: "notification-list" }
const _hoisted_3 = { class: "notification-replacement-car-name" }
const _hoisted_4 = { class: "notification-borrows" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "notification-replacement-car-name" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "notification-replacement-car-name -coming" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "notification-title" }, " Des véhicules de remplacement semblent ne pas avoir été rendus ", -1 /* HOISTED */)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.replacements, (replacement) => {
        return (_openBlock(), _createElementBlock("div", {
          key: replacement.id,
          class: "notification-replacement"
        }, [
          _createElementVNode("div", _hoisted_3, _toDisplayString( replacement.replacement_car.registration) + " | " + _toDisplayString(replacement.replacement_car.brand) + " | " + _toDisplayString(replacement.replacement_car.model), 1 /* TEXT */),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "notification-replacement-delimiter" }, null, -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
              class: "notification-replacement-block -in-progress",
              onClick: $event => ($props.onSelectCar(replacement.car_incharge))
            }, [
              _createVNode($setup["TriangleAlert"], {
                size: "18",
                class: "notification-replacement-alert"
              }),
              _createElementVNode("div", _hoisted_6, _toDisplayString(replacement.car_incharge.owner_last_name) + " | " + _toDisplayString(replacement.car_incharge.model), 1 /* TEXT */),
              _createTextVNode(" " + _toDisplayString($setup.getNumericDate(replacement.start_date)) + " - " + _toDisplayString($setup.getNumericDate(replacement.end_date)), 1 /* TEXT */)
            ], 8 /* PROPS */, _hoisted_5),
            _createElementVNode("button", {
              class: "notification-replacement-block -coming",
              onClick: $event => ($props.onSelectCar(replacement.next_replacement.car_incharge))
            }, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(replacement.next_replacement.car_incharge.model) + " | " + _toDisplayString(replacement.next_replacement.car_incharge.owner_last_name), 1 /* TEXT */),
              _createTextVNode(" " + _toDisplayString($setup.getNumericDate(replacement.next_replacement.start_date)) + " - " + _toDisplayString($setup.getNumericDate(replacement.next_replacement.end_date)), 1 /* TEXT */)
            ], 8 /* PROPS */, _hoisted_7)
          ])
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}