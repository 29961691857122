import { Application } from "@hotwired/stimulus"

import Modal from "../../apps/ui/static_src/ui/components/modal/Modal.js"
import Tooltip from "../../apps/ui/static_src/ui/components/tooltip/Tooltip.js"
import Label from "../../apps/ui/static_src/ui/components/label/Label.js"
import Color from "../../apps/ui/static_src/ui/components/color/Color.js"

const app = Application.start()
app.register("modal", Modal)
app.register("tooltip", Tooltip)
app.register("label", Label)
app.register("color", Color)
