import { defineStore } from "pinia"

import { getCars, updateCarInCharge } from "../services/api.js"
import { STATES_LIST } from "../utils/states.js"
import { serializeCar } from "../../../../companies/static_src/companies/serializers/car.js"

const createBaseCarInChargeModel = (companyId) => {
  return {
    registration: null,
    brand: null,
    model: null,
    state: "coming",
    start_work: null,
    sheet_metal_work_hours: 0,
    painting_hours: 0,
    mechanic_hours: 0,
    sheet_metal_state: "todo",
    painting_state: "todo",
    mechanic_state: "todo",
    owner_first_name: null,
    owner_last_name: null,
    owner_phone_number: null,
    owner_phone_number_2: null,
    owner_email: null,
    owner_address: null,
    first_registration_date: null,
    due_date: null,
    breakage_date: null,
    expertise_date: null,
    waiting_date: null,
    invoice_ready: false,
    need_replacement: false,
    complete_file: false,
    repair_order: false,
    valid_support: false,
    is_deleted: false,
    foreground_note: "",
    background_note: "",
    from_tow_truck: false,
    wreck_delivered: false,
    is_seriously_damaged: false,
    company_id: companyId,
    insurances: [],
    replacements: [],
    delayed_works: [],
  }
}

export const useCarsStore = defineStore("cars-list", {
  state: () => ({
    cars: [],
    query: "",
    selectedStates: [],
    loadingData: false,
    currentUrl: "",
    env: null,
    focusedCar: null,
    carInChargeModalOpen: false,
    insuranceModalOpen: false,
    nextPage: 0,
  }),
  getters: {
    baseUrl: (state) => {
      return `/${state.env.company_slug}_${state.env.company_pk}/`
    },
    pageUrl: (state) => {
      return `${state.baseUrl}vehicules/en-charge/`
    },
    currentQuery: (state) => {
      const statesString = state.selectedStates.map((s) => s?.value).join("&state=")
      return `query=${state.query}&state=${statesString}`
    },
  },
  actions: {
    async init(env, params, loadCars = true) {
      this.loadingData = true

      // init env
      this.env = env

      // init filters
      this.query = params.query || ""
      if (params.selectedStates.length === 1 && params.selectedStates[0] === "") this.selectedStates = []
      else this.selectedStates = params.selectedStates.map((state) => STATES_LIST.find((s) => s.value === state)) || []

      // fetch data
      if (loadCars) await this.fetchCars(1)

      this.loadingData = false
    },
    async fetchCars(page) {
      const { data } = await getCars({ query: this.query, states: this.selectedStates.map((s) => s?.value) }, this.env.company_pk, page)
      if (page === 1) this.cars = data.results.map(serializeCar)
      else this.cars.push(...data.results.map(serializeCar))
      this.nextPage = data.next ? page + 1 : null
    },
    resetFilters() {
      this.query = ""
      this.selectedStates = []
      this.nextPage = 1
      this.fetchCars(1)
    },
    openCarInChargeForm(car) {
      this.focusedCar = createBaseCarInChargeModel(this.env.company_pk)
      Object.assign(this.focusedCar, car)
      this.carInChargeModalOpen = true
    },
    updateCarInCharge(property, value, event = null) {
      if (event) {
        event.preventDefault()
        event.stopPropagation()
      }

      this.focusedCar[property] = value
    },
    async addMofifications(newCar) {
      const index = this.cars.findIndex((car) => car.id === newCar.id)
      this.cars[index] = newCar
      await updateCarInCharge({ ...newCar, insurance_ids: newCar.insurances.map((i) => i.id) })
      this.carInChargeModalOpen = false
    },
    addInsurance(insurance) {
      this.focusedCar.insurances.push(insurance)
    },
    removeInsurance(insurance) {
      const index = this.focusedCar.insurances.findIndex(i => i.id === insurance.id)
      this.focusedCar.insurances.splice(index, 1)
    },
  },
})
