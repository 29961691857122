import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "replacement-header"
}
const _hoisted_2 = {
  key: 1,
  class: "replacement-header"
}
const _hoisted_3 = { class: "replacement-container" }
const _hoisted_4 = { class: "replacement-car-selecter" }
const _hoisted_5 = { style: {"width":"15rem"} }
const _hoisted_6 = {
  class: "replacement-car-insurance-groups",
  style: {"width":"5rem","text-align":"center"}
}
const _hoisted_7 = {
  class: "replacement-car-valid",
  style: {"width":"10rem"}
}
const _hoisted_8 = {
  key: 0,
  class: "replacement-car-borrow-dates",
  style: {"width":"10rem"}
}
const _hoisted_9 = { class: "replacement-car-borrow-date" }
const _hoisted_10 = { class: "replacement-car-borrow-date" }
const _hoisted_11 = { class: "replacement-car-dates" }
const _hoisted_12 = {
  key: 1,
  class: "conflict-replacements"
}
const _hoisted_13 = { class: "conflict-message" }
const _hoisted_14 = { class: "conflict-replacement" }
const _hoisted_15 = {
  key: 2,
  class: "conflict-replacements"
}
const _hoisted_16 = { class: "conflict-message" }
const _hoisted_17 = { class: "replacement-car-form-validate" }
const _hoisted_18 = ["disabled"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createBlock($setup["Dialog"], {
    visible: $setup.calendarStore.replacementModalOpen,
    "onUpdate:visible": _cache[5] || (_cache[5] = $event => (($setup.calendarStore.replacementModalOpen) = $event)),
    modal: "",
    onAfterHide: $setup.reset
  }, {
    header: _withCtx(() => [
      (!$setup.replacement.id)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _cache[6] || (_cache[6] = _createElementVNode("span", null, "Fournir un véhicule de remplacement pour", -1 /* HOISTED */)),
            _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
            _createTextVNode(" " + _toDisplayString($setup.focusedCar.model) + " | " + _toDisplayString($setup.focusedCar.owner_last_name), 1 /* TEXT */)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _cache[8] || (_cache[8] = _createElementVNode("span", null, "Modifier l'emprunt pour", -1 /* HOISTED */)),
            _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
            _createTextVNode(" " + _toDisplayString($setup.focusedCar.model) + " | " + _toDisplayString($setup.focusedCar.owner_last_name), 1 /* TEXT */)
          ]))
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["FloatLabel"], { variant: "on" }, {
          default: _withCtx(() => [
            _createVNode($setup["AutoComplete"], {
              id: "car_replacement",
              ref: "autoCompleteRef",
              modelValue: $setup.query,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.query) = $event)),
              style: {"width":"30rem"},
              suggestions: $setup.replacementsSuggestions,
              onFocus: $setup.onFocus,
              onItemSelect: $setup.handleSelect
            }, {
              header: _withCtx(() => _cache[10] || (_cache[10] = [
                _createElementVNode("div", { class: "replacement-car-header" }, [
                  _createElementVNode("div", { style: {"width":"15rem"} }, " Véhicules "),
                  _createElementVNode("div", { style: {"width":"5rem","text-align":"center"} }, " Groupes "),
                  _createElementVNode("div", { style: {"width":"10rem","text-align":"center"} }, " Prise en charge "),
                  _createElementVNode("div", { style: {"width":"10rem","text-align":"center"} }, " Indisponibilité ")
                ], -1 /* HOISTED */)
              ])),
              option: _withCtx((slotProps) => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("span", _hoisted_5, _toDisplayString(slotProps.option.registration) + " - " + _toDisplayString(slotProps.option.brand) + " - " + _toDisplayString(slotProps.option.model), 1 /* TEXT */),
                  _createElementVNode("div", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.option.insurance_groups, (group, index) => {
                      return _withDirectives((_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: _normalizeClass(`car__card-state -${group.color}`)
                      }, null, 2 /* CLASS */)), [
                        [
                          _directive_tooltip,
                          group.name,
                          void 0,
                          { bottom: true }
                        ]
                      ])
                    }), 128 /* KEYED_FRAGMENT */))
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    ($setup.hasMatchingInsurances(slotProps.option.insurance_groups))
                      ? (_openBlock(), _createBlock($setup["Check"], {
                          key: 0,
                          size: "18",
                          class: "replacement-car-valid-icon"
                        }))
                      : (_openBlock(), _createBlock($setup["X"], {
                          key: 1,
                          size: "18",
                          class: "replacement-car-invalid-icon"
                        }))
                  ]),
                  (slotProps.option.start_date)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, _toDisplayString(slotProps.option.start_date), 1 /* TEXT */),
                        _createVNode($setup["ArrowBigRight"], {
                          size: "18",
                          class: "replacement-car-borrow-icon"
                        }),
                        _createElementVNode("div", _hoisted_10, _toDisplayString(slotProps.option.end_date), 1 /* TEXT */)
                      ]))
                    : _createCommentVNode("v-if", true)
                ])
              ]),
              empty: _withCtx(() => _cache[11] || (_cache[11] = [
                _createElementVNode("div", { class: "replacement-car-empty" }, " Aucun véhicule ne correspond à votre recherche ", -1 /* HOISTED */)
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue", "suggestions"]),
            _cache[12] || (_cache[12] = _createElementVNode("label", { for: "car_replacement" }, "Véhicule de remplacement", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        }),
        _createElementVNode("div", _hoisted_11, [
          _createVNode($setup["FloatLabel"], { variant: "on" }, {
            default: _withCtx(() => [
              _createVNode($setup["DatePicker"], {
                id: "replacement-start-date",
                modelValue: $setup.replacement.start_date,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.replacement.start_date) = $event)),
                style: {"width":"14.5rem"},
                "date-format": "dd/mm/yy",
                "min-date": $setup.minDate
              }, null, 8 /* PROPS */, ["modelValue", "min-date"]),
              _cache[13] || (_cache[13] = _createElementVNode("label", { for: "replacement-start-date" }, "Début du prêt", -1 /* HOISTED */))
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode($setup["FloatLabel"], { variant: "on" }, {
            default: _withCtx(() => [
              _createVNode($setup["DatePicker"], {
                id: "replacement-end-date",
                modelValue: $setup.replacement.end_date,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.replacement.end_date) = $event)),
                style: {"width":"14.5rem"},
                "date-format": "dd/mm/yy"
              }, null, 8 /* PROPS */, ["modelValue"]),
              _cache[14] || (_cache[14] = _createElementVNode("label", { for: "replacement-end-date" }, "Fin du prêt", -1 /* HOISTED */))
            ]),
            _: 1 /* STABLE */
          })
        ]),
        ($setup.replacement.id)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "checkbox",
              onClick: _cache[4] || (_cache[4] = $event => ($setup.replacement.finished = !$setup.replacement.finished, $event.stopPropagation(), $event.preventDefault()))
            }, [
              _createVNode($setup["Checkbox"], {
                modelValue: $setup.replacement.finished,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.replacement.finished) = $event)),
                "input-id": "finished",
                binary: true,
                class: "checkbox-replacement"
              }, null, 8 /* PROPS */, ["modelValue"]),
              _cache[15] || (_cache[15] = _createElementVNode("label", {
                for: "finished",
                style: {"cursor":"pointer","color":"var(--color-text-secondary)"}
              }, "Véchiule rendu", -1 /* HOISTED */))
            ]))
          : _createCommentVNode("v-if", true),
        ($setup.conflictReplacements.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createVNode($setup["TriangleAlert"], { size: "18" }),
                _createTextVNode(" Le véhicule de remplacement est emprunté par " + _toDisplayString($setup.conflictReplacements.length === 1 ? "un" : $setup.conflictReplacements) + " autre" + _toDisplayString($setup.conflictReplacements.length === 1 ? '':'s') + " client" + _toDisplayString($setup.conflictReplacements.length === 1 ? '':'s') + " aux mêmes dates ", 1 /* TEXT */)
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.conflictReplacements, (conflictReplacement) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: conflictReplacement.id,
                  class: "conflict-replacements-list"
                }, [
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode($setup["ReplacementPartial"], {
                      replacement: conflictReplacement,
                      "with-owner": ""
                    }, null, 8 /* PROPS */, ["replacement"])
                  ])
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
          : _createCommentVNode("v-if", true),
        ($setup.conflictMaintenanceTimes.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createVNode($setup["TriangleAlert"], { size: "18" }),
                _cache[16] || (_cache[16] = _createTextVNode(" Le véhicule de remplacement est en maintenance aux mêmes dates "))
              ])
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("button", {
            class: "replacement-car-form-button",
            disabled: !$setup.replacement.start_date || !$setup.replacement.end_date || !$setup.replacement.replacement_car || $setup.conflictReplacements.length > 0 || $setup.conflictMaintenanceTimes.length > 0,
            onClick: $setup.handleValidate
          }, " Valider ", 8 /* PROPS */, _hoisted_18)
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible"]))
}